/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Grid from '@solussaude/core/Grid';

import { PaymentIcon, WarningIcon } from '@solussaude/icons';

import TitlePage from '@solussaude/core/Surfaces/Title';
import Page from '@solussaude/core/Surfaces/Page';
import TableCRUD from '@solussaude/core/DataDisplay/TableCRUD';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import SolusAPI from '@solussaude/services/SolusAPI';
import defaultTheme from '../../styles/themes/default';
import { renderTable } from './settings';
import { Container } from './styles';

let mapping;
const getMapping = () => mapping;

export default function Payments() {
  const theme = defaultTheme;
  const [state, setState] = React.useState({
    loading: true,
    successApi: true,
    apiData: [],
  });

  React.useEffect(() => {
    const search = async () => {
      const dados = await SolusAPI.get('api/pagamentos/show');

      setState({ loading: false, successApi: true, apiData: dados.data });
    };
    search();
  }, []);

  const settings = renderTable(
    {
      refGetMapping: getMapping,
    },
    state.apiData,
  );

  const renderBodyTable = () => {
    if (state.apiData.length > 0) {
      return <TableCRUD settings={settings} inLoading={state.loading} />;
    }
    return <></>;
  };

  const renderDefaultError = () => {
    if (state.successApi && state.apiData.length === 0) {
      return (
        <>
          <BodyInfo
            title="Nenhum registro"
            body="Não encontrado nenhum pagamento com erros."
            noDivider
          />
        </>
      );
    }

    return (
      <>
        <BodyInfo
          icon={<WarningIcon color="error" />}
          title="Opss..."
          body="Não foi possivel carregar os pagamentos, tente novamente mais tarde."
          noDivider
        />
      </>
    );
  };

  return (
    <>
      <TitlePage>Pagamentos</TitlePage>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Container>
            <Page title="Registros de pagamentos" icon={PaymentIcon}>
              <Grid item sm={12} md={12}>
                {state.successApi && state.apiData.length > 0
                  ? renderBodyTable()
                  : renderDefaultError()}
              </Grid>
            </Page>
          </Container>
        </Grid>
      </Grid>
    </>
  );
}
