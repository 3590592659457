/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Checkbox from '@solussaude/core/Inputs/Checkbox';
import ActionButton from '@solussaude/core/Helpers/ActionButtons';
import Button from '@solussaude/core/Inputs/Button';
import SolusAPI from '@solussaude/services/SolusAPI';

import {
  Container,
  LoginPanel,
  LoginHeader,
  LoginBody,
  ContainerWrapper,
  ContainerPlan,
  TitlePlan,
  Plan,
} from './styles';
import defaultTheme from '../../../styles/themes/default';

let formValues;
const getFormValues = () => formValues;

export default function LinkAccount() {
  const theme = defaultTheme;
  const checks = [];

  const handleContainerClick = (e, target) => {
    document.getElementById(target).click();
  };

  const setChecks = (newValue, id) => {
    checks[id] = newValue;
  };

  const handleSubmit = async (e) => {
    const res = { ...checks };
    e.preventDefault();
    const query = '/jogador';
    await SolusAPI.post(query, res);
  };

  return (
    <Container>
      <LoginPanel>
        <LoginHeader>
          <img className="auth_logo" src={theme.logo} alt="User" />
        </LoginHeader>
        <LoginBody>
          <h3>Cadastro de senha</h3>
          {/* <Form settings={settings} setValues={setFormValues} /> */}
          <ContainerWrapper>
            <ContainerPlan>
              <Checkbox
                styled
                data={{ elem_id: 'plano1' }}
                elem_id="plano1"
                refGetValues={getFormValues}
                refSetValues={setChecks}
              />
              <Plan onClick={(e) => handleContainerClick(e, 'plano1')}>
                <TitlePlan>Unimed básico 3</TitlePlan>
                <p>
                  Código: <b>123456</b>
                </p>
              </Plan>
            </ContainerPlan>
            <ContainerPlan>
              <Checkbox
                styled
                data={{ elem_id: 'plano2' }}
                elem_id="plano2"
                refGetValues={getFormValues}
                refSetValues={setChecks}
              />
              <Plan onClick={(e) => handleContainerClick(e, 'plano2')}>
                <TitlePlan>Unimed básico 3</TitlePlan>
                <p>
                  Código: <b>123456</b>
                </p>
              </Plan>
            </ContainerPlan>
            <ContainerPlan>
              <Checkbox
                styled
                data={{ elem_id: 'plano3' }}
                elem_id="plano3"
                refGetValues={getFormValues}
                refSetValues={setChecks}
              />
              <Plan onClick={(e) => handleContainerClick(e, 'plano3')}>
                <TitlePlan>Unimed básico 3</TitlePlan>
                <p>
                  Código: <b>123456</b>
                </p>
              </Plan>
            </ContainerPlan>
          </ContainerWrapper>
          <ActionButton>
            <Button
              variant="contained"
              color="primary"
              text="enviar"
              onClick={(e) => handleSubmit(e)}
            />
          </ActionButton>
        </LoginBody>
      </LoginPanel>
    </Container>
  );
}
