import styled from 'styled-components';

const defaultDirection = 'flex-end';
export const ModalResponse = styled.div.attrs({
  className: 'modal',
})`
  width: 500px;
  padding: 15px;
  background-color: #272822;
`;

export const Container = styled.div`
  .sc-jzJRlG {
    margin-bottom: 0;
  }
`;

export const ActionButtons = styled.div`
  padding-top: 10px;
  justify-content: ${(props) =>
    props.direction !== undefined ? props.direction : defaultDirection};
  display: flex;
  flex: 1;

  .MuiStepper-horizontal {
    padding-bottom: 0px;
  }
`;

export const ContainerDetail = styled.div`
  background-color: #272822;

  & > form > div {
    margin: 0;
    max-width: 100%;
  }

  /* pre {
    font-size: 15px;
  } */
`;
