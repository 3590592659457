import React from 'react';

import { SendIcon } from '@solussaude/icons';

export const temp = {};
export const renderForm = ({ onHandleClick }, id) => {
  const label = 'Cadastrar senha';
  return {
    header: {
      description:
        'Informe o seu e-mail e crie uma senha para finalizar o cadastro da senha.',
    },
    form: [
      {
        label,
        inputs: [
          [
            {
              elem_id: 'userID',
              elem_val: id,
            },
          ],
          [
            {
              elem_id: 'cpf',
              type: 'InputMask',
              label: 'CPF',
              required: true,
              typeMask: 'cpf',
            },
          ],
          [
            {
              elem_id: 'senha',
              type: 'InputPassword',
              label: 'Senha',
              required: true,
              fullWidth: true,
              helperText:
                'A senha deve possuir uma letra maiúscula, uma minúscula e um caractere especial!',
            },
          ],
          [
            {
              elem_id: 'repetir_senha',
              type: 'InputPassword',
              label: 'Repetir Senha',
              required: true,
              fullWidth: true,
              helperText:
                'A senha deve possuir uma letra maiúscula, uma minúscula e um caractere especial!',
            },
          ],
          [
            {
              elem_id: 'btnSubmit',
              type: 'Button',
              text: 'Cadastrar senha',
              onClick: () => onHandleClick(),
              fullWidth: true,
              color: 'primary',
              variant: 'contained',
              endIcon: <SendIcon />,
            },
          ],
        ],
      },
    ],
  };
};
