import React from 'react';

import { SaveIcon } from '@solussaude/icons';
import BodyFormModal from '@solussaude/core/Helpers/BodyFormModal';
import { renderForm } from './settings';

export default function EmailSettings() {
  return (
    <>
      <BodyFormModal
        title="Configuração de E-mail"
        description="As configurações devem ser as fornecidas do provedor."
        formSettings={renderForm}
        descriptionSuccess={<p>E-mail salvo com sucesso!</p>}
        btTextSuccess="Alterar"
        iconSuccess={<SaveIcon />}
      />
    </>
  );
}
