/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Grid from '@solussaude/core/Grid';
import TitlePage from '@solussaude/core/Surfaces/Title';
import Page from '@solussaude/core/Surfaces/Page';
import Form from '@solussaude/core/CustomForm';
import Button from '@solussaude/core/Inputs/Button';
import MensConfirmacao from '@solussaude/core/Helpers/MensConfirmacao';
import WebContext from '@solussaude/services/WebContext';
import SolusAPI from '@solussaude/services/SolusAPI';
import { CheckCircleIcon, WarningIcon } from '@solussaude/icons';
import defaultTheme from '../../../styles/themes/default';
import { renderForm } from './settings';
import { ActionButtons } from './styles';

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

export default function BeneficiaryArea() {
  const theme = defaultTheme;
  const webContext = React.useContext(WebContext);

  const [state, setState] = React.useState({
    segundaViaCarteira: false,
    boleto: false,
    atendimentos: false,
    carencia: false,
    rede: false,
    movimentacaoUsuario: false,
    alteraSenha: false,
    demonstrativoIRPF: false,
    boletoVencido: false,
    atualizaBoleto: false,
    infoUsuario: false,
    acessoCancelado: false,
    permiteGuia: false,
  });

  React.useEffect(() => {
    const init = async () => {
      const dados = await SolusAPI.get(
        'api/settings/configuracao-area-beneficiario',
      );
      if (dados.status === 200) {
        if (Object.keys(dados.data).length > 0) {
          setState({
            ...state,
            segundaViaCarteira: dados.data.segundaViaCarteira === 'S',
            boleto: dados.data.boleto === 'S',
            atendimentos: dados.data.atendimentos === 'S',
            carencia: dados.data.carencia === 'S',
            rede: dados.data.rede === 'S',
            movimentacaoUsuario: dados.data.movimentacaoUsuario === 'S',
            alteraSenha: dados.data.alteraSenha === 'S',
            demonstrativoIRPF: dados.data.demonstrativoIRPF === 'S',
            boletoVencido: dados.data.boletoVencido === 'S',
            atualizaBoleto: dados.data.atualizaBoleto === 'S',
            infoUsuario: dados.data.infoUsuario === 'S',
            acessoCancelado: dados.data.acessoCancelado === 'S',
            permiteGuia: dados.data.permiteGuia === 'S',
          });
        }
      }
    };
    init();
  }, []);

  const onChange = () => {
    const {
      carteira,
      boleto,
      atendimentos,
      carencia,
      rede,
      movimentacao,
      senha,
      ir,
      boletoVencido,
      boju,
      infoUsuario,
      acessoCancelado,
      permiteGuia,
    } = getFormValues();

    if (boletoVencido) {
      setState({
        ...state,
        segundaViaCarteira: carteira,
        boleto,
        atendimentos,
        carencia,
        rede,
        movimentacaoUsuario: movimentacao,
        alteraSenha: senha,
        demonstrativoIRPF: ir,
        boletoVencido: true,
        atualizaBoleto: boju,
        infoUsuario,
        acessoCancelado,
        permiteGuia,
      });
    } else {
      setState({
        ...state,
        segundaViaCarteira: carteira,
        boleto: false,
        atendimentos,
        carencia,
        rede,
        movimentacaoUsuario: movimentacao,
        alteraSenha: senha,
        demonstrativoIRPF: ir,
        boletoVencido: false,
        atualizaBoleto: boju,
        infoUsuario,
        acessoCancelado,
        permiteGuia,
      });
    }
  };

  const handleSubmit = async () => {
    const {
      carteira,
      boleto,
      atendimentos,
      carencia,
      rede,
      movimentacao,
      senha,
      ir,
      boletoVencido,
      boju,
      infoUsuario,
      acessoCancelado,
      permiteGuia,
    } = getFormValues();

    const dados = await SolusAPI.post(
      'api/settings/configuracao-beneficiario',
      {
        carteira: carteira ? 'S' : 'N',
        boleto: boleto ? 'S' : 'N',
        atendimentos: atendimentos ? 'S' : 'N',
        carencia: carencia ? 'S' : 'N',
        rede: rede ? 'S' : 'N',
        movimentacao: movimentacao ? 'S' : 'N',
        senha: senha ? 'S' : 'N',
        ir: ir ? 'S' : 'N',
        boletoVencido: boletoVencido ? 'S' : 'N',
        boju: boju ? 'S' : 'N',
        infoUsuario: infoUsuario ? 'S' : 'N',
        acessoCancelado: acessoCancelado ? 'S' : 'N',
        permiteGuia: permiteGuia ? 'S' : 'N',
      },
    );

    const handleCloseModal = () => {
      webContext.showModal({
        open: false,
      });
    };

    if (dados.status === 200) {
      webContext.showModal({
        open: true,
        renderBody: (
          <MensConfirmacao
            title="Alterado com sucesso!"
            IconTitle={<CheckCircleIcon color="primary" />}
            description="Configurações alteradas com sucesso"
            handleClick={handleCloseModal}
          />
        ),
      });
    } else {
      webContext.showModal({
        open: true,
        renderBody: (
          <MensConfirmacao
            title="Erro"
            IconTitle={<WarningIcon color="error" />}
            description="Ops...ocorreu um erro, tente novamente mais tarde!"
            handleClick={handleCloseModal}
          />
        ),
      });
    }
  };

  return (
    <>
      <TitlePage>Configuração da Área de Beneficiários</TitlePage>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Page title="Configuração da Área de Beneficiários">
            <Grid item sm={12} md={12}>
              <Form
                settings={renderForm(state, onChange)}
                setValues={setFormValues}
              />
            </Grid>
            <Grid item sm={12} md={6}>
              <ActionButtons direction="flex-start">
                <Button
                  text="Salvar"
                  variant="contained"
                  color="secondary"
                  fullWidth={false}
                  onClick={(e) => handleSubmit(e)}
                />
              </ActionButtons>
            </Grid>
          </Page>
        </Grid>
      </Grid>
    </>
  );
}
