/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import SolusAPI from '@solussaude/services/SolusAPI';
import Layout from '@solussaude/core/Templates/Solus_modelo';
import WebContext from '@solussaude/services/WebContext';
import { isPromise } from '@solussaude/services/Utils';

import { isAuthenticated } from '../services/auth';
import { pages } from './pages';
import { menus } from './menu';

export default function Routes() {
  const { module } = React.useContext(WebContext);
  const [state, setState] = React.useState({
    loading: true,
    success: false,
  });

  if (isAuthenticated()) {
    localStorage.setItem('basename', '/adm');
    module.active = 'administrador';
    module.administrador.userAuth = SolusAPI.get('api/settings/operador');
  }

  React.useEffect(() => {
    const check = async () => {
      const dados = await SolusAPI.get('api/teste');

      setState({
        loading: false,
        success: dados.success,
      });
    };
    check();
  }, []);

  const idUser = async () => {
    await isPromise(module.administrador.userAuth.id);
  };

  const online = () => {
    if (state.loading === false) {
      return state.success;
    }
    return null;
  };

  const MainRoute = () => (
    <Route
      render={(props) => {
        if (isAuthenticated()) {
          if (online()) {
            return (
              <Redirect
                to={{
                  pathname: '/adm/dashboard',
                  state: { from: props.locationm, success: state.success },
                }}
              />
            );
          }
          return (
            <Redirect
              to={{
                pathname: '/adm/500',
                state: { from: props.location, success: state.success },
              }}
            />
          );
        }
        if (online()) {
          return (
            <Redirect
              to={{
                pathname: '/adm/login',
                state: { from: props.location, success: state.success },
              }}
            />
          );
        }
        return (
          <Redirect
            to={{
              pathname: '/adm/500',
              state: { from: props.location, success: state.success },
            }}
          />
        );
      }}
    />
  );

  const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated()) {
          if (online()) {
            return (
              <Layout
                page={Component}
                menus={menus}
                modulo="ADMINISTRADOR"
                dadosPlano={false}
                id={idUser()}
                {...props}
              />
            );
          }
          return (
            <Redirect
              to={{
                pathname: '/adm/500',
                state: { from: props.location, success: state.success },
              }}
            />
          );
        }
        if (online()) {
          return (
            <Redirect
              to={{
                pathname: '/adm/login',
                state: { from: props.location, success: state.success },
              }}
            />
          );
        }
        return (
          <Redirect
            to={{
              pathname: '/adm/500',
              state: { from: props.location, success: state.success },
            }}
          />
        );
      }}
    />
  );

  if (state.loading) return null;
  return (
    <BrowserRouter>
      <Switch>
        <MainRoute path="/adm/" exact />
        <PrivateRoute path="/adm/dashboard" component={pages.Dashboard} />
        <Route path="/adm/login" exact component={pages.Login} />
        <Route path="/adm/forgot-password" component={pages.ForgotPassword} />
        <PrivateRoute
          path="/adm/change-password"
          component={pages.ChangePassword}
        />
        <Route
          path="/adm/register-password"
          component={pages.RegisterPassword}
        />
        <Route path="/adm/new-password" component={pages.NewPassword} />
        <Route path="/adm/link-account" component={pages.LinkAccount} />
        <Route path="/adm/without-action" component={pages.WithoutAction} />
        <PrivateRoute path="/adm/settings" component={pages.Settings} />
        <PrivateRoute path="/adm/theme-color" component={pages.ThemeColor} />
        <PrivateRoute
          path="/adm/area-beneficiario"
          component={pages.BeneficiaryArea}
        />
        <PrivateRoute
          path="/adm/email-settings"
          component={pages.EmailSettings}
        />
        <PrivateRoute path="/adm/debug" component={pages.Debug} />
        <PrivateRoute path="/adm/testapp" component={pages.TestApp} />
        <PrivateRoute
          path="/adm/testapp-webusuario"
          component={pages.WebUsuario}
        />
        <PrivateRoute path="/adm/payments" component={pages.Payments} />
        <PrivateRoute
          path="/adm/testapp-webprestador"
          component={pages.WebPrestador}
        />
        <PrivateRoute path="/adm/servers" component={pages.Servers} />
      </Switch>
    </BrowserRouter>
  );
}
