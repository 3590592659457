import React from 'react';

import { HomeIcon, SendIcon } from '@solussaude/icons';

export const temp = {};

export const renderForm = ({ onHandleClick, onHandleClickBack }) => {
  const label = 'Cadastrar senha';
  return {
    header: {
      description:
        'Você já possui uma senha cadastrada. Deseja recuperar sua senha?',
    },
    form: [
      {
        label,
        inputs: [
          [
            {
              elem_id: 'home',
              type: 'Button',
              text: 'Ir para login',
              onClick: () => onHandleClickBack(),
              fullWidth: true,
              color: 'secondary',
              variant: 'contained',
              endIcon: <HomeIcon />,
            },
          ],
          [
            {
              elem_id: 'btnSubmit',
              type: 'Button',
              text: 'Recuperar senha',
              onClick: () => onHandleClick(),
              fullWidth: true,
              color: 'primary',
              variant: 'contained',
              endIcon: <SendIcon />,
            },
          ],
        ],
      },
    ],
  };
};
