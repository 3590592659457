/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import { DashboardIcon } from '@solussaude/icons';

import Page from '@solussaude/core/Surfaces/Page';

const modelo3 = (
  <>
    <Page title="Meu painel" icon={DashboardIcon} />
  </>
);

export default function Dashboard() {
  return modelo3;
}
