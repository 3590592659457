import styled from 'styled-components';

export const ContainerDetail = styled.div`
  background-color: #272822;

  .MuiInputLabel-formControl {
    color: #fff !important;
  }

  .MuiInput-formControl {
    input {
      color: #aaa !important;
    }
  }
`;

export const ContainerJSON = styled.div``;
