/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Grid from '@solussaude/core/Grid';

import { BlurOnIcon, SendIcon, WarningIcon } from '@solussaude/icons';

import TitlePage from '@solussaude/core/Surfaces/Title';
import Page from '@solussaude/core/Surfaces/Page';
import TableCRUD from '@solussaude/core/DataDisplay/TableCRUD';
import ActionButtons from '@solussaude/core/Helpers/ActionButtons';
import Button from '@solussaude/core/Inputs/Button';
import WebContext from '@solussaude/services/WebContext';
import MensConfirmacao from '@solussaude/core/Helpers/MensConfirmacao';
import SolusAPI from '@solussaude/services/SolusAPI';
// import SolusAPI from '../../../services/SolusAPI';

import defaultTheme from '../../../styles/themes/default';
import { renderTable } from './settings';

let selections = [];
let mapping;
const getMapping = () => mapping;
const setMapping = (newMapping) => {
  mapping = newMapping;
};

export default function WebPrestador() {
  const webContext = React.useContext(WebContext);
  const theme = defaultTheme;

  const handleCloseModal = () => {
    webContext.showModal({
      open: false,
      renderBody: webContext.modal.renderBody,
    });
  };

  const prepareAction = async (row, stateRow) => {
    let action;
    let dadosAPI;

    if (row.tipo === 'GET') {
      action = row.rota;
      const jsonSend = JSON.parse(
        stateRow.jsonSend !== '' ? stateRow.jsonSend : row.jsonSend,
      );
      Object.keys(jsonSend).forEach((keyJsonSend) => {
        action = action.replace(`:${keyJsonSend}`, jsonSend[keyJsonSend]);
      });

      dadosAPI = await SolusAPI.get(action, jsonSend, true);
    }

    if (row.tipo === 'POST') {
      action = row.rota;
      const jsonSend = JSON.parse(
        stateRow.jsonSend !== '' ? stateRow.jsonSend : row.jsonSend,
      );
      Object.keys(jsonSend).forEach((keyJsonSend) => {
        action = action.replace(`:${keyJsonSend}`, jsonSend[keyJsonSend]);
      });

      dadosAPI = await SolusAPI.post(action, jsonSend, true);
    }

    return dadosAPI;
  };

  function processTest() {
    const tempSelect = selections;
    Object.keys(tempSelect).forEach(async (key) => {
      const row = tempSelect[key];
      const stateRow = getMapping()[tempSelect[key].index];

      const dadosAPI = await prepareAction(row, stateRow.state);

      stateRow.setState({
        ...stateRow.state,
        loading: false,
        situacao: dadosAPI.success ? 'A' : 'E',
        response: dadosAPI.data,
      });
    });
  }

  const setAllRowsLoading = () => {
    let dados;
    const tempSelect = selections;

    if (tempSelect.length > 0) {
      Object.keys(tempSelect).forEach((key) => {
        dados = getMapping()[tempSelect[key].index];

        dados.setState({
          ...dados.state,
          loading: true,
        });
      });
      processTest();
    } else {
      webContext.showModal({
        open: true,
        renderBody: (
          <MensConfirmacao
            title="Erro"
            IconTitle={<WarningIcon color="error" />}
            description={
              <p>Nenhuma rota selecionada para realizar os testes</p>
            }
            handleClick={handleCloseModal}
          />
        ),
      });
    }
  };

  function onSelectionChange(row) {
    selections = row;
  }

  setMapping({
    ...getMapping(),
    webUsuario: {
      setAllRowsLoading,
      onSelectionChange,
    },
  });

  const settings = renderTable({
    onSelectionChange,
    refGetMapping: getMapping,
    refSetMapping: setMapping,
  });

  return (
    <>
      <TitlePage>TestApp</TitlePage>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Page title="Web Usuário" icon={BlurOnIcon}>
            <Grid item sm={12} md={12}>
              <TableCRUD settings={settings} />
            </Grid>
            <ActionButtons>
              <Button
                text="Testar rotas"
                variant="contained"
                color="primary"
                endIcon={<SendIcon />}
                fullWidth={false}
                onClick={() => setAllRowsLoading()}
              />
            </ActionButtons>
          </Page>
        </Grid>
      </Grid>
    </>
  );
}
