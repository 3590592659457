/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import Login from '@solussaude/core/Login';
import { renderForm } from './settings';
import defaultTheme from '../../styles/themes/default';

export default function Main(props) {
  const theme = defaultTheme;

  return (
    <>
      <Login
        theme={theme}
        props={props}
        module="ADMINISTRADOR"
        title="Acesso ao portal do administrador"
        settings={renderForm}
        redirectURI="/"
        basename="/adm"
      />
    </>
  );
}
