/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import RenderStyleJSON from '@solussaude/core/RenderStyleJSON';
// import WebContext from "@solussaude/services/WebContext";
import { dateFormat, genKey, formatMoney } from '@solussaude/services/Utils';

/*
const handleCloseModal = (context) => {
  context.showModal({
    open: false,
    renderBody: "",
  });
};
*/
const RenderDetail = ({ row }) => (
  // const modal = React.useContext(WebContext);
  // const response = "error";

  <RenderStyleJSON json={row.json} />
);
const processData = (apiData) => {
  const array = [];

  if (Array.isArray(apiData)) {
    (apiData || []).forEach((pay) => {
      array.push({
        nnumereceb: pay.nnumereceb,
        nnumepaga: pay.nnumepaga,
        data: dateFormat(pay.datapagamento),
        valor: `R$ ${formatMoney(pay.valor)}`,
        json: pay.response,
      });
    });
  }

  return array;
};

export const temp = {};
export const renderTable = ({ refGetMapping }, apiData) => ({
  table: {
    options: {
      description:
        'Essa tabela lista os pagamentos que apresentaram erros na baixa',
      editableTable: false,
      pagination: true,
      search: false,
      pageSize: 10,
      detailPanel: (row) => (
        <RenderDetail key={genKey()} row={row} refGetMapping={refGetMapping} />
      ),
    },
    columns: [
      {
        title: 'ID Recebimento',
        field: 'nnumereceb',
      },
      {
        title: 'ID Mensalidade',
        field: 'nnumepaga',
      },
      {
        title: 'Data',
        field: 'data',
      },
      {
        title: 'Valor',
        field: 'valor',
      },
    ],
    data: processData(apiData),
  },
});
