/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import { CreditCardIcon, LockIcon } from '@solussaude/icons';
import Button from '@solussaude/core/Inputs/Button';

import { ContainerOption } from './styles';

const customComp = (props) => (
  <ContainerOption key={0}>
    <Button
      text="Cadastrar senha"
      fullWidth={false}
      size="small"
      color="secondary"
      onClick={() => {
        props.history.push('/register-password');
      }}
    />
  </ContainerOption>
);

export const renderForm = ({ onClickEntrar, onClickEsqueceu, props }) => ({
  form: [
    {
      label: 'Endereco',
      inputs: [
        [
          {
            elem_id: 'user',
            type: 'InputText',
            label: 'Login',
            required: true,
            icon: CreditCardIcon,
            onClick: () => onClickEntrar(),
          },
        ],
        [
          {
            elem_id: 'pwd',
            type: 'InputText',
            label: 'Senha',
            required: true,
            icon: LockIcon,
            typeInputText: 'password',
            onClick: () => onClickEntrar(),
          },
        ],
        [customComp(props)],
        // [
        //   {
        //     flex: 6,
        //   },
        //   {
        //     elem_id: 'cadastrar',
        //     type: 'Button',
        //     text: 'Cadastrar senha',
        //     fullWidth: false,
        //     variant: 'text',
        //     flex: 6,
        //     size: 'small',
        //     onClick: () => onClickCadastrar(),
        //   },
        // ],
        [
          {
            elem_id: 'btnSubmit',
            type: 'Button',
            text: 'Entrar',
            fullWidth: true,
            color: 'primary',
            variant: 'contained',
            onClick: () => onClickEntrar(),
          },
        ],
        [
          {
            type: 'Button',
            text: 'Esqueceu sua senha?',
            fullWidth: true,
            variant: 'text',
            onClick: () => onClickEsqueceu(),
          },
        ],
      ],
    },
  ],
});
