/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { CheckIcon, WarningIcon, ErrorIcon } from '@solussaude/icons';

import CircularIndeterminate from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import Icon from '@solussaude/core/Helpers/Icon';
import Form from '@solussaude/core/CustomForm';
import RenderStyleJSON from '@solussaude/core/RenderStyleJSON';
import { genKey } from '@solussaude/services/Utils';

import { ContainerDetail } from './styles';

const RenderStatus = ({ index, refGetMapping, refSetMapping }) => {
  const [state, setState] = React.useState({
    loading: false,
    situacao: '',
    response: '{}',
    jsonSend: '',
    detailRenderID: undefined,
  });

  refSetMapping({
    ...refGetMapping(),
    [index]: {
      state,
      setState,
    },
  });

  React.useEffect(() => {
    if (state.detailRenderID !== undefined) {
      state.detailRenderID.setValues({
        ...state.detailRenderID.values,
        jsonReturn: state.response,
        jsonSend:
          state.jsonSend !== ''
            ? state.jsonSend
            : state.detailRenderID.values.jsonSend,
      });
    }
  }, [state.detailRenderID, state.response]);

  if (state.loading) return <CircularIndeterminate margin="none" size={22} />;

  const retrunSituacao = () => {
    if (state.situacao === 'A') {
      return (
        <>
          <Icon icon={CheckIcon} styles={{ color: '#23af11' }}>
            <span style={{ color: '#23af11' }}>Concluído</span>
          </Icon>
        </>
      );
    }
    if (state.situacao === 'E') {
      return (
        <>
          <Icon icon={ErrorIcon} style={{ color: '#dc0f0f' }}>
            <span style={{ color: '#dc0f0f' }}>Erros encontrados</span>
          </Icon>
        </>
      );
    }
    return (
      <>
        <Icon icon={WarningIcon} styles={{ color: '#afa311' }}>
          <span style={{ color: '#afa311' }}>Não verificado</span>
        </Icon>
      </>
    );
  };

  return (
    <>
      <b>{retrunSituacao()}</b>
    </>
  );
};

function RenderDetail({ row, refGetMapping, refSetMapping }) {
  let valuesDetail;
  const string = 'renderDetail';
  const thisRenderIndex = string + row.index;
  const mapping = refGetMapping();
  const [values, setValues] = React.useState({
    jsonSend: row.jsonSend,
    jsonReturn: refGetMapping()[row.index].state.response,
  });

  const formSetValues = (newValues) => {
    valuesDetail = newValues;
  };

  refSetMapping({
    ...refGetMapping(),
    [thisRenderIndex + row.index]: {
      values,
      setValues,
    },
  });

  const atualizaMapeamento = (newValue) => {
    setValues({
      ...values,
      jsonSend: newValue,
    });

    mapping[row.index].setState({
      ...mapping[row.index].state,
      jsonSend: newValue,
    });
  };

  const handleClickReset = () => atualizaMapeamento(row.jsonSend);

  const handleClickChangeJSON = () =>
    atualizaMapeamento(valuesDetail.json_field);

  const singleTest = () => {
    const selectOne = [];
    selectOne[0] = row;
    refGetMapping().webUsuario.onSelectionChange(selectOne);
    refGetMapping().webUsuario.setAllRowsLoading();
  };

  const funMappingComp = () => {
    if (refGetMapping()[row.index].state.detailRenderID === undefined) {
      refGetMapping()[row.index].setState({
        ...refGetMapping()[row.index].state,
        detailRenderID: {
          values,
          setValues,
        },
      });
    }
  };

  React.useEffect(() => {
    funMappingComp();
  });

  const settings = {
    form: [
      {
        label: 'JSON de envio',
        inputs: [
          [
            // Row
            [
              // Column
              <RenderStyleJSON key={genKey()} json={values.jsonSend} />,
            ],
          ],
          [
            {
              // Column
              elem_id: 'json_field',
              type: 'InputText',
              label: 'Enviar outro JSON',
              flex: 6,
            },
          ],
          [
            {
              // Column
              type: 'Button',
              text: 'Testar',
              fullWidth: true,
              href: '',
              flex: 3,
              color: 'secondary',
              variant: 'contained',
              onClick: () => singleTest(),
            },
            {
              // Column
              type: 'Button',
              text: 'Alterar JSON',
              fullWidth: true,
              href: '',
              flex: 3,
              color: 'secondary',
              variant: 'contained',
              onClick: () => handleClickChangeJSON(),
            },
            {
              // Column
              type: 'Button',
              text: 'Voltar JSON Padrão',
              fullWidth: true,
              href: '',
              flex: 3,
              color: 'secondary',
              variant: 'contained',
              onClick: () => handleClickReset(),
            },
          ],
        ],
      },
      {
        label: 'Retorno da requisição',
        inputs: [
          [
            // Row
            [
              // renderJSONResponse
              <RenderStyleJSON key={genKey()} json={values.jsonReturn} />,
            ],
          ],
        ],
      },
    ],
  };

  return (
    <>
      <ContainerDetail>
        <Form settings={settings} setValues={formSetValues} />
      </ContainerDetail>
    </>
  );
}

export const temp = {};
export const renderTable = ({
  refGetMapping,
  refSetMapping,
  onSelectionChange,
}) => ({
  table: {
    options: {
      description:
        'Aqui estão mapeados todas as rotas do modulo Web Usuário, você pode testar todas as requisições de uma vez ou testar apenas uma rota, você pode testar com o JSON padrão ou informar um outro JSON na requisição.',
      editableTable: false,
      pagination: false,
      search: false,
      selection: true,
      onSelectionChange,
      detailPanel: (row) => (
        <RenderDetail
          key={genKey()}
          row={row}
          refGetMapping={refGetMapping}
          refSetMapping={refSetMapping}
        />
      ),
    },
    columns: [
      {
        title: 'Descrição',
        field: 'descricao',
      },
      {
        title: 'Rota',
        field: 'rota',
      },
      {
        title: 'Tipo',
        field: 'tipo',
      },
      {
        title: 'Situação',
        field: 'situacao',
      },
    ],
    data: [
      {
        index: 0,
        descricao: 'Dados do beneficiário',
        rota: 'api/beneficiarios',
        // rota: 'api/beneficiarios/:id',
        tipo: 'GET',
        situacao: (
          <RenderStatus
            index={0}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 4545}',
      },
      {
        index: 1,
        descricao: "Mensalidades do beneficiário, tela 'Mensalidades'",
        rota: 'api/beneficiarios/contasReceber',
        // rota: 'api/beneficiarios/:id/contasReceber',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={1}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend:
          '{"regInicial": 0, "regFinal": 100, "dataInicial": "01/01/1900", "dataFinal": "31/12/2100"}',
      },
      {
        index: 2,
        descricao: "Carencias do beneficiário, tela 'Meu plano'",
        rota: 'api/beneficiarios/carencias',
        // rota: 'api/beneficiarios/:id/carencias',
        tipo: 'GET',
        situacao: (
          <RenderStatus
            index={2}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 1816}',
      },
      {
        index: 3,
        descricao: "Plano do beneficiário, tela 'Meu Plano'",
        rota: 'api/beneficiarios/planos',
        // rota: "api/benef''iciarios/:id/planos",
        tipo: 'GET',
        situacao: (
          <RenderStatus
            index={3}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 112}',
      },
      {
        index: 4,
        descricao:
          "Relação de planos e produtos, tela 'Informações / Relação de planos e produtos'",
        rota: 'api/beneficiarios/planosProdutos',
        // rota: 'api/beneficiarios/:id/planosProdutos',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={4}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"situacao": "A", "web": "S", "ordem": "C"}',
      },
      {
        index: 5,
        descricao:
          "Protocolos do beneficiário, tela 'Informações / Meus protocolos'",
        rota: 'api/beneficiarios/protocolos',
        // rota: 'api/beneficiarios/:id/protocolos',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={5}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 566348}',
      },
      {
        index: 6,
        descricao: "Telefones do beneficiário, tela 'Meu perfil'",
        rota: 'api/beneficiarios/telefones',
        // rota: 'api/beneficiarios/:id/telefones',
        tipo: 'GET',
        situacao: (
          <RenderStatus
            index={6}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 1816, "tipo": "T"}',
      },
      {
        index: 7,
        descricao:
          'Módulos disponíveis para o usuário, barra superior no ícone ao lado de SAIR',
        rota: 'api/authenticate/modulos',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={7}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 1}',
      },
      {
        index: 8,
        descricao:
          'Histórico de agendamento do beneficiário, tela Histórico de Agendamento',
        rota: 'api/agendamentos/beneficiario',
        tipo: 'GET',
        situacao: (
          <RenderStatus
            index={8}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 1816}',
      },
      {
        index: 9,
        descricao:
          'Valores dos filtros para a busca de médicos, tela Agendamento ',
        rota: 'api/agendamentos/combo',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={9}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend:
          '{"tipo": "R", "unidade": 0, "cidade": 0, "prestador":  0, "especialidade": 0}',
      },
      {
        index: 10,
        descricao: 'Realizar login, tela Login ',
        rota: 'api/login',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={10}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"user": "jao", "pwd": "zotape"}',
      },
      {
        index: 11,
        descricao: 'Verificar autorização, tela Login ',
        rota: 'api/autorizacao',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={11}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"modulo": "PRESTADOR"}',
      },
      {
        index: 12,
        descricao: 'Procedimentos de uma carência, tela Contrato ',
        rota: 'api/beneficiarios/procedimentos-carencia',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={12}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 1}',
      },
      {
        index: 13,
        descricao: 'Guia médico, tela Guia médico ',
        rota: 'api/beneficiarios/guia-medico',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={13}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend:
          '{"cidade": "CACERES", "uf": "MT", "tipoPrestador": "C", "especialidade": 0}',
      },
      {
        index: 14,
        descricao: 'Especialidades do guia médico, tela Guia médico ',
        rota: 'api/beneficiarios/especialidades',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={14}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"tipoPrestador": 0}',
      },
      {
        index: 15,
        descricao: 'Tipos prestador do guia médico, tela Guia médico ',
        rota: 'api/beneficiarios/tipos-prestador',
        tipo: 'GET',
        situacao: (
          <RenderStatus
            index={15}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 1816}',
      },
      {
        index: 16,
        descricao: 'Estados do guia médico, tela Guia médico ',
        rota: 'api/beneficiarios/localidades-uf',
        tipo: 'GET',
        situacao: (
          <RenderStatus
            index={16}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 1816}',
      },
      {
        index: 17,
        descricao: 'Cidades do guia médico, tela Guia médico ',
        rota: 'api/beneficiarios/localidades-cidades',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={17}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"estado": "MT"}',
      },
      {
        index: 18,
        descricao: 'Status pedido, tela Consulta pedido ',
        rota: 'api/beneficiarios/status-pedido',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={18}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 1816, "id": 535}',
      },
      {
        index: 19,
        descricao: 'Lista pedido, tela Consulta pedido ',
        rota: 'api/beneficiarios/lista-pedidos',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={19}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 1816, "id": 21548}',
      },
      {
        index: 20,
        descricao: 'Lista itens do pedido, tela Consulta pedido ',
        rota: 'api/beneficiarios/lista-itens-pedidos',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={20}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 1816, "id": 535}',
      },
      {
        index: 21,
        descricao: 'Lista prestadores do procedimento, tela Consulta pedido ',
        rota: 'api/beneficiarios/lista-prestadores-procedimento',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={21}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend:
          '{"id": 52798,  "cidade": "CACERES", "estado": "MT", "idBeneficiario": 52798, "idGuia": 65,"codigoProcedimento": 40101010}',
      },
      {
        index: 22,
        descricao: 'Lista limites de utilização, tela Limites de utilização',
        rota: 'api/beneficiarios/limites-utilizacao',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={22}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 1816}',
      },
      {
        index: 23,
        descricao: 'Demonstrativo para I.R, tela Mensalidades',
        rota: 'api/beneficiarios/demonstrativoIR',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={23}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 1816, "contrato": 7537, "ano": "01/01/2018"}',
      },
      {
        index: 24,
        descricao: 'Lista de e-mails do beneficiário, tela Meu perfil',
        rota: 'api/beneficiarios/lista-email',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={24}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 1816, "tipo": "P"}',
      },
      {
        index: 25,
        descricao: 'Remove telefone do beneficiário, tela Meu perfil',
        rota: 'api/beneficiarios/remove-telefone',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={25}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 1816, "idTelefone": "", "origem": "P"}',
      },
      {
        index: 26,
        descricao: 'Adere boleto digital, tela Serviços',
        rota: 'api/beneficiarios/boleto-digital',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={26}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend:
          '{"id": 1816, "email": "teste@teste.com.br", "email_2": "", "celular": "43910019206"}',
      },
      {
        index: 27,
        descricao: 'Cancela adesão do boleto digital, tela Serviços',
        rota: 'api/beneficiarios/cancelar-boleto-digital',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={27}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend:
          '{"id": 1816, "tipoEndereco": "C", "cep":"86047622", "tipoLogradouro": 81, "logradouro": "JOSE ROQUE SALTON", "numero": "285", "complemento": "", "bairro": "TERRA BONITA", "estado": "PR", "cidade": "LONDRINA", "idEndereco": 645444, "tipoEndereco": "F", "principal": "N"}',
      },
      {
        index: 28,
        descricao: 'Cancelamento do contrato, tela Serviços',
        rota: 'api/beneficiarios/cancelamento-contrato',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={28}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend:
          '{"id": 1816, "novoTitular": "0", "usuarios": "0", "telefone": "43910019206", "email": "teste@teste.com.br", "endereco": "RUA JOSE ROQUE SALTON 285", "tipo": "C", "motivo": 335996 }',
      },
    ],
  },
});
