/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Grid from '@solussaude/core/Grid';

import {
  BuildIcon,
  PersonIcon,
  ColorLensIcon,
  /* EmailIcon, */
} from '@solussaude/icons';

import TitlePage from '@solussaude/core/Surfaces/Title';
import Page from '@solussaude/core/Surfaces/Page';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import ButtonInfo from '@solussaude/core/Inputs/ButtonInfo';
// import EmailSettings from "./Email";
import defaultTheme from '../../styles/themes/default';

export default function Settings() {
  const theme = defaultTheme;

  return (
    <>
      <TitlePage>Configurações</TitlePage>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Page icon={BuildIcon}>
            <BodyInfo
              title="Configurações da Web Usuário"
              body="Você pode configurar alguns serviços pelo portal, segue as opções abaixo."
            />
            <ButtonInfo
              title="Área de beneficiário"
              icon={<PersonIcon />}
              link="/adm/area-beneficiario"
            />
            <ButtonInfo
              title="Cor do tema"
              icon={<ColorLensIcon />}
              link="/adm/theme-color"
            />
            {/* <ButtonInfo
              title="E-mail"
              icon={<EmailIcon />}
              renderModal={<EmailSettings />}
            /> */}
          </Page>
        </Grid>
      </Grid>
    </>
  );
}
