/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import Form from '@solussaude/core/CustomForm';
import { renderForm } from './settings';
import { Container, LoginPanel, LoginHeader, LoginBody } from './styles';
import defaultTheme from '../../../styles/themes/default';

// eslint-disable-next-line no-unused-vars
let formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

export default function WithoutAction(props) {
  const theme = defaultTheme;

  const onHandleClick = () => {
    props.history.push('/forgot-password');
  };

  const onHandleClickBack = () => {
    props.history.push('/adm/login');
  };

  const settings = renderForm({ onHandleClick, onHandleClickBack });
  return (
    <Container>
      <LoginPanel>
        <LoginHeader>
          <img className="auth_logo" src={theme.logo} alt="User" />
        </LoginHeader>
        <LoginBody>
          <h3>Cadastro de senha</h3>
          <Form settings={settings} setValues={setFormValues} />
        </LoginBody>
      </LoginPanel>
    </Container>
  );
}
