/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import Grid from '@solussaude/core/Grid';
import TitlePage from '@solussaude/core/Surfaces/Title';
import Page from '@solussaude/core/Surfaces/Page';
import Form from '@solussaude/core/CustomForm';
import Button from '@solussaude/core/Inputs/Button';
import MenuFloat from '@solussaude/core/Utils/MenuFloat';
import SendRequestModal from '@solussaude/core/Helpers/SendRequestModal';
import Popper from '@solussaude/core/Utils/Popper';
import SolusAPI from '@solussaude/services/SolusAPI';
import WebContext from '@solussaude/services/WebContext';
import { SketchPicker } from 'react-color';

import defaultTheme from '../../../styles/themes/default';
import { renderForm, renderFormFont } from './settings';

export default function SettingsForm() {
  let valuesColor;
  const { showModal } = React.useContext(WebContext);
  const [state, setState] = React.useState({
    inLoading: true,
    dataAPI: {},
  });
  // let valuesFont;
  const theme = defaultTheme;

  const setValuesColor = (formValues) => {
    valuesColor = formValues;
  };

  const thisReload = () => {
    setState((old) => ({ ...old, reload: !old.reload }));
  };

  const saveSettings = () => {
    const comp = (
      <SendRequestModal
        title="Atualização de cores"
        descriptionSuccess={
          <p>Alteração de cores foi realizada com sucesso!</p>
        }
        action="api/settings/setColors"
        data={{ valuesColors: valuesColor }}
        afterSend={thisReload}
      />
    );
    showModal({
      open: true,
      renderBody: comp,
    });
    // const dadosFromAPI = await SolusAPI.post('settings/setColors', valuesColor);
    // Object.keys(valuesColor).forEach(data => {
    //   const val = valuesColor[data];
    //   if (val !== undefined && val !== null) localStorage.setItem(data, val);
    // });
    // window.location.reload();
  };

  React.useEffect(() => {
    const getFromAPI = async () => {
      if (!state.inLoading) {
        setState((old) => ({
          ...old,
          inLoading: true,
        }));
      }

      const dadosFromAPI = await SolusAPI.get('api/settings/webcolors');

      setState((old) => ({
        ...old,
        inLoading: false,
        dataAPI: dadosFromAPI.data || {},
      }));
    };
    getFromAPI();
  }, [state.reload]);

  if (state.inLoading) return <></>;

  return (
    <>
      <TitlePage>Configurações Web</TitlePage>

      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={7}>
          <Page title="Color tool">
            <Grid item sm={12} md={12}>
              <Form
                settings={renderForm({ dataAPI: state.dataAPI })}
                setValues={setValuesColor}
              />
            </Grid>
          </Page>
        </Grid>

        <Grid item sm={12} md={5}>
          <Page title="Font custom">
            <Grid item sm={12} md={12}>
              <Form settings={renderFormFont} />
            </Grid>
          </Page>
        </Grid>
      </Grid>

      <Button
        text="Gravar"
        variant="contained"
        color="secondary"
        fullWidth={false}
        onClick={() => saveSettings()}
      />

      <MenuFloat>
        <Popper>
          <SketchPicker />
        </Popper>
      </MenuFloat>
    </>
  );
}
