import styled from 'styled-components';

export const direction = 'flex-end';
export const ActionButtons = styled.div`
  padding-top: 10px;
  justify-content: ${(props) =>
    props.direction !== undefined ? props.direction : direction};
  display: flex;
  flex: 1;

  .MuiStepper-horizontal {
    padding-bottom: 0px;
  }
`;
