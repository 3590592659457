import styled from 'styled-components';

export const ContainerOption = styled.div`
  flex: 1;
  height: 50px;
  margin-top: 10px;
  float: right;

  button {
    font-size: 12px !important;
  }
`;
