/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Button from '@solussaude/core/Inputs/Button';
import Form from '@solussaude/core/CustomForm';
import RenderStyleJSON from '@solussaude/core/RenderStyleJSON';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import SolusAPI from '@solussaude/services/SolusAPI';
import WebContext from '@solussaude/services/WebContext';
import { dateFormat, genKey } from '@solussaude/services/Utils';
import { ActionButtons, ModalResponse, ContainerDetail } from './styles';

const jogadores = [
  {
    id: 1,
    nome: 'Cristiano Ronaldo',
    clube: 'Juventus',
    camisa: '7',
    jsonRequest: '12345678',
  },
  {
    id: 2,
    nome: 'Messi',
    clube: 'Barcelona',
    camisa: '10',
    jsonRequest: '12345678',
  },
  {
    id: 3,
    nome: 'Mbappe',
    clube: 'PSG',
    camisa: '10',
    jsonRequest: '12345678',
  },
  {
    id: 4,
    nome: 'Hazard',
    clube: 'Real Madrid',
    camisa: '10',
    jsonRequest: '12345678',
  },
];

const handleCloseModal = (context) => {
  context.showModal({
    open: false,
    renderBody: '',
  });
};

const handleResponse = (props) => (
  <RenderStyleJSON style={{ maxWidth: '100%' }} json={props} />
);

const Detail = (props) => {
  const [state, setState] = React.useState({ loading: true, response: [] });
  const modal = React.useContext(WebContext);
  const { row } = props;
  const { tipo, rota } = row;

  React.useEffect(() => {
    const initialize = async () => {
      if (tipo === 'GET') {
        const dados = await SolusAPI.get(rota.substring(1));
        setState({ ...state, response: dados });
      } else if (tipo === 'POST') {
        // const dados = await SolusAPI.post(props.row.rota.substring(1));
      }

      setState({ ...state, response: [], loading: false });
    };

    initialize();
  }, []);

  if (state.loading) return <CircularIndeterminateModal />;

  return (
    <>
      <ModalResponse style={{ maxWidth: '100%' }}>
        {handleResponse(state.response)}
        <ActionButtons direction="flex-start">
          <Button
            text="Fechar"
            variant="contained"
            color="secondary"
            fullWidth={false}
            onClick={() => handleCloseModal(modal)}
          />
        </ActionButtons>
      </ModalResponse>
    </>
  );
};

function getRandomInt(min, max) {
  const newMin = Math.ceil(min);
  const newMax = Math.floor(max);
  return Math.floor(Math.random() * (newMax - newMin)) + newMin;
}

const RenderDetail = ({ row }) => {
  const modal = React.useContext(WebContext);
  const response = 'error';

  const processResponse = (dados) => {
    modal.showModal({
      open: true,
      renderBody: (
        <Detail row={row} modal={modal} response={response} data={dados} />
      ),
      styleContainer: 'margin: 0;',
    });
  };

  const settings = (json) => {
    const random = getRandomInt(1, 4);

    return {
      form: [
        {
          inputs: [
            [
              // Column
              [<RenderStyleJSON key={genKey()} json={json[random]} />],
            ],
            [
              {
                // Column
                type: 'Button',
                text: 'Testar',
                fullWidth: true,
                flex: 4,
                color: 'secondary',
                variant: 'contained',
                onClick: () => processResponse(json[random]),
              },
            ],
          ],
        },
      ],
    };

    // json.forEach((dados) => {
    //   forms.push({
    //     label: `Ocorrência ${dados.id}`,
    //     inputs: [
    //       [
    //         // Row
    //         [
    //           // Column
    //           <RenderStyleJSON json={dados[random]} />,
    //         ],
    //       ],
    //       [
    //         {
    //           // Column
    //           type: 'Button',
    //           text: 'Testar',
    //           fullWidth: true,
    //           flex: 4,
    //           color: 'secondary',
    //           variant: 'contained',
    //           onClick: () => processResponse(dados),
    //         },
    //       ],
    //     ],
    //   });
    // });
    // return {
    //   form: forms,
    // };
  };

  return (
    <>
      <ContainerDetail>
        <Form settings={settings(jogadores)} />
      </ContainerDetail>
    </>
  );
};

const processData = (apiData) => {
  const array = [];
  if (apiData.length > 0) {
    apiData.forEach((log, i) => {
      array.push({
        index: i,
        modulo: log.module,
        rota: log.url,
        tipo: log.method,
        qtdOcorrencias: 4,
        dataOcorrencia: dateFormat(log.created_at),
        jsonSend: '{}',
      });
    });
  }

  return array;
};

export const temp = {};
export const renderTable = ({ refGetMapping }, apiData) => ({
  table: {
    options: {
      description: 'Essa tabela lista os logs de erros registrados na Web',
      editableTable: false,
      pagination: true,
      search: false,
      pageSize: 10,
      detailPanel: (row) => (
        <RenderDetail key={genKey()} row={row} refGetMapping={refGetMapping} />
      ),
    },
    columns: [
      {
        title: 'Módulo',
        field: 'modulo',
      },
      {
        title: 'Rota',
        field: 'rota',
      },
      {
        title: 'Tipo',
        field: 'tipo',
      },
      {
        title: 'Quantidade de Ocorrências',
        field: 'qtdOcorrencias',
      },
      {
        title: 'Data da Ocorrência',
        field: 'dataOcorrencia',
      },
    ],
    data: processData(apiData),
  },
});
