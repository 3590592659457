/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import Form from '@solussaude/core/CustomForm';
import ActionButtons from '@solussaude/core/Helpers/ActionButtons';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import Button from '@solussaude/core/Inputs/Button';
import SolusAPI from '@solussaude/services/SolusAPI';
import WebContext from '@solussaude/services/WebContext';

import { WarningIcon } from '@solussaude/icons';

import { renderForm } from './settings';
import { Container, LoginPanel, LoginHeader, LoginBody } from './styles';
import defaultTheme from '../../styles/themes/default';

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

export default function RegisterPassword(props) {
  const webContext = React.useContext(WebContext);
  const theme = defaultTheme;
  const [state, setState] = React.useState({ loading: false });

  const onCloseModal = () => {
    webContext.showModal({
      open: false,
    });
  };

  // if (state.loading) {
  //   webContext.showModal({
  //     open: true,
  //     renderBody: <CircularIndeterminate />
  //   })
  // }

  const onHandleClick = async () => {
    const values = getFormValues();

    setState({ loading: true });

    setTimeout(() => {
      setState({ loading: false });
    }, 1500);

    const dados = await SolusAPI.post('api/v1/beneficiarios/findByCPF', values);

    if (dados.data.length > 0) {
      if (dados.data2.length > 0) {
        props.history.push('/without-action');
      }
      props.history.push({
        pathname: '/new-password',
        state: dados.data[0].pessoa,
      });
    } else {
      webContext.showModal({
        open: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<WarningIcon color="error" />}
              title="Usuário não encontrado!"
              body="Não foi possível encontrar o usuário com o CPF e a data de nascimento informado!"
            />
            <ActionButtons direction="flex-end">
              <Button
                text="Fechar"
                variant="contained"
                color="secondary"
                fullWidth={false}
                onClick={() => onCloseModal()}
              />
            </ActionButtons>
          </>
        ),
      });
    }
    // if (status === 1) {
    //   props.history.push("/new-password");
    // } else if (status === 2) {
    //   props.history.push("/link-account");
    // } else if (status === 3) {
    //   props.history.push("/without-action");
    // }
  };

  const onHandleClickBack = () => {
    props.history.push('/adm/login');
  };

  const settings = renderForm({ onHandleClick, onHandleClickBack });
  return (
    <Container>
      <LoginPanel>
        <LoginHeader>
          <img className="auth_logo" src={theme.logo} alt="User" />
        </LoginHeader>
        {state.loading ? (
          <CircularIndeterminateModal />
        ) : (
          <LoginBody>
            <h3>Cadastro de senha</h3>
            <Form settings={settings} setValues={setFormValues} />
          </LoginBody>
        )}
      </LoginPanel>
    </Container>
  );
}
