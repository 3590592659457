/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import axios from 'axios';

import {
  StorageIcon,
  WarningIcon,
  ArrowBackIosIcon,
  CheckCircleIcon,
  CloseIcon,
} from '@solussaude/icons';

import Grid from '@solussaude/core/Grid';
import TitlePage from '@solussaude/core/Surfaces/Title';
import Page from '@solussaude/core/Surfaces/Page';
import TableCRUD from '@solussaude/core/DataDisplay/TableCRUD';
import ActionButtons from '@solussaude/core/Helpers/ActionButtons';
import Button from '@solussaude/core/Inputs/Button';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
// import SolusAPI from '@solussaude/services/SolusAPI';
import WebContext from '@solussaude/services/WebContext';
import defaultTheme from '../../styles/themes/default';
import { renderTable } from './settings';
import { Container } from './styles';

let mapping;
const getMapping = () => mapping;
const setMapping = (newMapping) => {
  mapping = newMapping;
};

export default function Servers() {
  const webContext = React.useContext(WebContext);
  const theme = defaultTheme;
  const [state, setState] = React.useState({
    loading: true,
    loadingModal: false,
    successApi: true,
    apiData: [],
    apiServer: [],
    apiDataServer: [],
  });

  React.useEffect(() => {
    setState({ ...state, loading: false });
    // const search = async () => {
    //   const dados = await SolusAPI.post('api/logs', {
    //     p_inicio: 0,
    //     p_limite: 50,
    //   });

    //   setState({ loading: false, successApi: true, apiData: dados.data });
    // };
    // search();
  }, []);

  if (state.loadingModal) return <CircularIndeterminateModal />;

  const handleCloseModal = () => {
    webContext.showModal({
      open: false,
    });
  };
  let dataTurnon;
  let dataServer;
  const turnOff = async (row) => {
    const { pid } = state.apiServer[row.index].data;
    handleCloseModal();
    await axios.post('http://localhost:9005/config', {
      action: 'desligar',
      api: row.path,
      env: row.endereco,
      pid,
    });
  };
  const turnon = async (row) => {
    handleCloseModal();
    dataTurnon = await axios.post('http://localhost:9005/config', {
      action: 'ligar',
      api: row.path,
      env: row.endereco,
    });

    try {
      dataServer = await axios.get(row.endereco);
      const temp = state.apiServer;
      temp[row.index] = dataTurnon;
      setState({
        ...state,
        loadingModal: false,
        apiDataServer: dataServer,
        apiServer: temp,
      });
      webContext.showModal({
        open: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<CheckCircleIcon color="primary" />}
              title="Servidor iniciado com sucesso!"
              body=""
              noDivider
            />
            <ActionButtons direction="flex-end">
              <Button
                text="Fechar"
                variant="contained"
                color="secondary"
                endIcon={<CloseIcon />}
                fullWidth={false}
                onClick={() => handleCloseModal()}
              />
            </ActionButtons>
          </>
        ),
      });
    } catch (err) {
      const temp = state.apiServer;
      temp[row.index] = dataTurnon;
      setState({
        ...state,
        loadingModal: false,
        apiDataServer: dataServer,
        apiServer: temp,
      });
      webContext.showModal({
        open: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<WarningIcon color="error" />}
              title="Não foi possível iniciar o servidor"
              body=""
              noDivider
            />
            <ActionButtons direction="flex-end">
              <Button
                text="Fechar"
                variant="contained"
                color="secondary"
                endIcon={<CloseIcon />}
                fullWidth={false}
                onClick={() => handleCloseModal()}
              />
            </ActionButtons>
          </>
        ),
      });
    }

    // if (dataServer) {
    //   alert('online');
    // } else {
    //   alert('offline');
    // }
  };

  const handleLigar = (row) => {
    webContext.showModal({
      open: true,
      renderBody: (
        <>
          <BodyInfo
            icon={<WarningIcon color="error" />}
            title={`Deseja mesmo ligar o servidor ${row.nome} ?`}
            body=""
            noDivider
          />
          <ActionButtons direction="space-between">
            <Button
              text="Voltar"
              variant="contained"
              color="secondary"
              endIcon={<ArrowBackIosIcon />}
              fullWidth={false}
              onClick={() => handleCloseModal()}
            />
            <Button
              text="Ligar"
              variant="contained"
              color="primary"
              endIcon={<CheckCircleIcon />}
              fullWidth={false}
              onClick={() => turnon(row)}
            />
          </ActionButtons>
        </>
      ),
    });
  };

  const handleDesligar = (row) => {
    webContext.showModal({
      open: true,
      renderBody: (
        <>
          <BodyInfo
            icon={<WarningIcon color="error" />}
            title={`Deseja mesmo desligar o servidor ${row.nome} ?`}
            body=""
            noDivider
          />
          <ActionButtons direction="space-between">
            <Button
              text="Voltar"
              variant="contained"
              color="secondary"
              endIcon={<ArrowBackIosIcon />}
              fullWidth={false}
              onClick={() => handleCloseModal()}
            />
            <Button
              text="Desligar servidor"
              variant="contained"
              color="primary"
              endIcon={<CheckCircleIcon />}
              fullWidth={false}
              onClick={() => turnOff(row)}
            />
          </ActionButtons>
        </>
      ),
    });
  };

  const settings = renderTable(
    {
      refGetMapping: getMapping,
      refSetMapping: setMapping,
    },
    state.apiData,
    handleLigar,
    handleDesligar,
  );

  const renderBodyTable = () => (
    <TableCRUD settings={settings} inLoading={state.loading} />
  );
  const renderDefaultError = (
    <>
      <BodyInfo
        icon={<WarningIcon color="error" />}
        title="Opss..."
        body="Não foi possivel carregar as api, tente novamente mais tarde."
        noDivider
      />
    </>
  );

  return (
    <>
      <TitlePage>Servidores</TitlePage>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Container>
            <Page icon={StorageIcon}>
              <Grid item sm={12} md={12}>
                {state.successApi ? renderBodyTable() : renderDefaultError}
              </Grid>
            </Page>
          </Container>
        </Grid>
      </Grid>
    </>
  );
}
