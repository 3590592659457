/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import Grid from '@solussaude/core/Grid';

import { BlurOnIcon, PersonIcon } from '@solussaude/icons';

import TitlePage from '@solussaude/core/Surfaces/Title';
import Page from '@solussaude/core/Surfaces/Page';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import ButtonInfo from '@solussaude/core/Inputs/ButtonInfo';
import defaultTheme from '../../styles/themes/default';

export default function TestApp() {
  const theme = defaultTheme;

  return (
    <>
      <TitlePage>TestApp Inteligence</TitlePage>
      <Grid container spacing={theme.grid.spacing}>
        <Grid item sm={12} md={12}>
          <Page icon={BlurOnIcon}>
            <BodyInfo
              title="Modulos"
              body="Escolha o modulo para testar as rotinas."
            />
            <ButtonInfo
              title="Área de beneficiário"
              icon={<PersonIcon />}
              link="/testapp-webusuario"
            />
            <ButtonInfo
              title="Área de prestador"
              icon={<PersonIcon />}
              link="/testapp-webprestador"
            />
          </Page>
        </Grid>
      </Grid>
    </>
  );
}
