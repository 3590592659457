/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import ForgotPassword from '@solussaude/core/ForgotPassword';
import defaultTheme from '../../styles/themes/default';

export default function ForgotPasswordPage(props) {
  const theme = defaultTheme;

  return <ForgotPassword props={props} theme={theme} basename="/adm" />;
}
