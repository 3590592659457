import React from 'react';

import {
  SettingsIcon,
  HomeIcon,
  // BugReportIcon,
  PaymentIcon,
  // BlurOnIcon,
  // StorageIcon,
} from '@solussaude/icons';

export const menus = [
  [
    {
      label: 'Home',
      link: '/adm/',
      icon: <HomeIcon color="secondary" />,
    },
    /* {
      label: 'TestApp intelligence',
      link: '/adm/testapp',
      icon: <BlurOnIcon color="secondary" />,
    },
    {
      label: 'Debug',
      link: '/adm/debug',
      icon: <BugReportIcon color="secondary" />,
    }, */
    {
      label: 'Pagamentos',
      link: '/adm/payments',
      icon: <PaymentIcon color="secondary" />,
    },
    /* {
      label: 'Servers',
      link: '/adm/servers',
      icon: <StorageIcon color="secondary" />,
    }, */
    {
      label: 'Configurações',
      link: '/adm/settings',
      icon: <SettingsIcon color="secondary" />,
    },
  ],
];
