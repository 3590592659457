export const temp = {};
export const renderForm = (apiData, onChange) => ({
  form: [
    {
      inputs: [
        [
          {
            elem_id: 'senha',
            elem_val: apiData.alteraSenha,
            type: 'Checkbox',
            label: 'Alteração de senha',
          },
        ],
        [
          {
            elem_id: 'rede',
            elem_val: apiData.rede,
            type: 'Checkbox',
            label: 'Consulta da rede credenciada',
          },
        ],
        [
          {
            elem_id: 'carencia',
            elem_val: apiData.carencia,
            type: 'Checkbox',
            label: 'Consulta de carências',
          },
        ],
        [
          {
            elem_id: 'ir',
            elem_val: apiData.demonstrativoIRPF,
            type: 'Checkbox',
            label: 'Demonstrativo de I.R.',
          },
        ],
        [
          {
            elem_id: 'boleto',
            elem_val: apiData.boleto,
            type: 'Checkbox',
            label: 'Impressão de boletos',
          },
        ],
        [
          {
            elem_id: 'boju',
            elem_val: apiData.atualizaBoleto,
            type: 'Checkbox',
            label: 'Atualização de boletos',
          },
        ],
        [
          {
            elem_id: 'boletoVencido',
            elem_val: apiData.boletoVencido,
            type: 'Checkbox',
            label: 'Impressao de boletos vencidos',
            onChange: () => onChange(),
          },
        ],
        [
          {
            elem_id: 'movimentacao',
            elem_val: apiData.movimentacaoUsuario,
            type: 'Checkbox',
            label: 'Inclusão de dependentes',
          },
        ],
        [
          {
            elem_id: 'atendimentos',
            elem_val: apiData.atendimentos,
            type: 'Checkbox',
            label: 'Relação de atendimentos',
          },
        ],
        [
          {
            elem_id: 'carteira',
            elem_val: apiData.segundaViaCarteira,
            type: 'Checkbox',
            label: 'Solicitação de carteirinha (PF e PFF)',
          },
        ],
        [
          {
            elem_id: 'infoUsuario',
            elem_val: apiData.infoUsuario,
            type: 'Checkbox',
            label: 'Informação de Beneficiário',
          },
        ],
        [
          {
            elem_id: 'acessoCancelado',
            elem_val: apiData.acessoCancelado,
            type: 'Checkbox',
            label: 'Acessível por usuários cancelados',
          },
        ],
        [
          {
            elem_id: 'permiteGuia',
            elem_val: apiData.permiteGuia,
            type: 'Checkbox',
            label: 'Permite impressão de guias',
          },
        ],
      ],
    },
  ],
});
