/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import ChangePassword from '@solussaude/core/ChangePassword';

export default function ChangePasswordPage() {
  return (
    <>
      <ChangePassword />
    </>
  );
}
