import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0px;
    padding: 0px;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    min-height: 100%;
  }
 
  p{
    margin: 0px;
  }

  body {
    background: #f5f8fa;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif !important;
    color: #86939e;
    line-height: 1.5;
    font-weight: 300 !important;
    letter-spacing: .2px;            
    -webkit-font-smoothing: antialiased !important;
  }

  .shadow{
    /* box-shadow: 0 0 6px rgba(0,0,0,.1) !important;         */
    box-shadow: 0 5px 5px 0 rgba(0,0,0,.26) !important;
  }
  .withoutShadow{
    box-shadow: none !important;
  }

  .auth_logo{
    width: 175px;
  }

  .contentCenter{
    display:flex;
    justify-content: center;
    align-items: center;    
  }

  .MuiButton-textSizeSmall{
    font-size:11px !important;
  }

  .MuiSvgIcon-colorPrimary{
    color: ${(props) => props.theme.palette.background.colorIcons} !important;
  }
  
  .MuiInputAdornment-positionStart{
    color: ${(props) => props.theme.palette.background.colorIcons} !important;
  }

`;
