import React from 'react';

const autenticacao = [
  {
    key: 0,
    name: 'Sem autenticação',
  },
  {
    key: 1,
    name: 'TLS',
  },
  {
    key: 2,
    name: 'SSL',
  },
];

export const renderForm = {
  // header:{
  //   description : ''
  // },
  form: [
    {
      // label       : '',
      inputs: [
        [
          {
            // Column
            elem_id: 'adm_config_email_servidor',
            type: 'InputMask',
            typeMask: 'smtp',
            label: 'Servidor SMTP',
          },
          {
            elem_id: 'adm_config_email_servidor_porta',
            type: 'InputText',
            label: 'Porta',
          },
        ],
        [
          // Row
          {
            // Column
            elem_id: 'adm_config_email',
            type: 'InputText',
            label: 'Usuário do servidor SMTP',
          },
        ],
        [
          // Row
          {
            // Column
            elem_id: 'adm_config_email_senha',
            type: 'InputText',
            typeInputText: 'password',
            label: 'Senha',
          },
        ],
        [
          {
            // Column
            elem_id: 'adm_config_email_autenticacao',
            value: autenticacao,
            type: 'RadioButton',
            name: 'Autenticação',
            label: 'Autenticação',
            disabled: false,
          },
        ],
      ],
    },
  ],
};

const tipoCancelamento = [
  {
    key: 0,
    name: 'Cancelamento de todo o contrato: Cancelar titular e dependentes',
  },
  {
    key: 1,
    name: 'Cancelamento somente do titular',
  },
  {
    key: 2,
    name: 'Cancelamento somente do dependente',
  },
];
const motivos = [
  { key: 0, name: 'A pedido do usuário' },
  { key: 1, name: 'Aposentado' },
  { key: 2, name: 'Cancelou pois não usa o plano' },
  { key: 3, name: 'Contrato temporário' },
  { key: 4, name: 'Demitido' },
  { key: 5, name: 'Dificuldade financeira' },
  { key: 6, name: 'Erro de cadastro' },
  { key: 7, name: 'Fraude/Utilização indevida' },
  { key: 8, name: 'Inadimplência/Sem débito' },
  { key: 9, name: 'Mensalidade duplicidade' },
];

export const cancelamento = {
  steps: [
    'Informações sobre o cancelamento',
    'Informações para contato',
    'Confirmação',
  ],
  stepContent: [
    {
      title:
        'Saiba sobre as Consequências do Cancelamento ou Exclusão do Contrato de Plano de Saúde:',
      text: (
        <>
          <span>Art. 15 da RN 412</span>
          <div>
            <p style={{ marginBottom: `${15}px` }}>
              I - eventual ingresso em novo plano de saúde poderá importar:
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              a) no cumprimento de novos períodos de carência, observado o
              disposto no inciso V do artigo 12, da Lei nº 9.656, de 3 de junho
              de 1998;
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              b) na perda do direito à portabilidade de carências, caso não
              tenha sido este o motivo do pedido, nos termos previstos na RN nº
              186, de 14 de janeiro de 2009, que dispõe, em especial, sobre a
              regulamentação da portabilidade das carências previstas no inciso
              V do art. 12 da Lei nº 9.656, de 3 de junho de 1998;
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              c) no preenchimento de nova declaração de saúde, e, caso haja
              doença ou lesão preexistente - DLP, no cumprimento de Cobertura
              Parcial Temporária - CPT, que determina, por um período
              ininterrupto de até 24 meses, a partir da data da contratação ou
              adesão ao novo plano, a suspensão da cobertura de Procedimentos de
              Alta Complexidade (PAC), leitos de alta tecnologia e procedimentos
              cirúrgicos;
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              d) na perda imediata do direito de remissão, quando houver,
              devendo o beneficiário arcar com o pagamento de um novo contrato
              de plano de saúde que venha a contratar;
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              II - efeito imediato e caráter irrevogável da solicitação de
              cancelamento do contrato ou exclusão de beneficiário, a partir da
              ciência da operadora ou administradora de benefícios;
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              III - as contraprestações pecuniárias vencidas e/ou eventuais
              coparticipações devidas, nos planos em pré-pagamento ou em
              pós-pagamento, pela utilização de serviços realizados antes da
              solicitação de cancelamento ou exclusão do plano de saúde são de
              responsabilidade do beneficiário;
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              IV - as despesas decorrentes de eventuais utilizações dos serviços
              pelos beneficiários após a data de solicitação de cancelamento ou
              exclusão do plano de saúde, inclusive nos casos de urgência ou
              emergência, correrão por sua conta;
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              {' '}
              V - a exclusão do beneficiário titular do contrato individual ou
              familiar não extingue o contrato, sendo assegurado aos dependentes
              já inscritos o direito à manutenção das mesmas condições
              contratuais, com a assunção das obrigações decorrentes; e
            </p>

            <p style={{ marginBottom: `${15}px` }}>
              {' '}
              VI – a exclusão do beneficiário titular do contrato coletivo
              empresarial ou por adesão observará as disposições contratuais
              quanto à exclusão ou não dos dependentes, conforme o disposto no
              inciso II do parágrafo único do artigo 18, da RN nº 195, de 14 de
              julho de 2009, que dispõe sobre a classificação e características
              dos planos privados de assistência à saúde, regulamenta a sua
              contratação, institui a orientação para contratação de planos
              privados de assistência à saúde e dá outras providências.
            </p>
          </div>
        </>
      ),
      checkbox:
        'Li e confirmo estar ciente das consequências do cancelamento ou exclusão do Contrato do Plano de Saúde.',
    },
    {
      text: (
        <>
          <p>
            {' '}
            No caso de manutenção dos dependentes, o responsável financeiro tem
            acesso via sistema das informações dos atendimentos prestados até
            que os dependentes atinjam a maioridade; após este prazo, em razão
            da manutenção do sigilo das informações, o acesso à relação dos
            atendimentos prestados somente será franqueado após autorização do
            beneficiário.
          </p>

          <p>
            É de responsabilidade do beneficiário titular/titular financeiro a
            solicitação de cancelamento, a operadora não se responsabiliza caso
            outra pessoa solicite no lugar do mesmo.
          </p>
        </>
      ),
      checkbox:
        'Confirmo estar ciente e de acordo com minhas responsabilidades.',
    },
  ],
};

export const renderDados = {
  header: {
    description: 'Informações para contato.',
  },
  form: [
    {
      label: 'Informações para contato',
      inputs: [
        [
          // Row
          {
            // Column
            elem_id: 'cancel_tel',
            elem_val: '',
            type: 'InputMask',
            typeMask: 'telefone',
            label: 'Telefone',
          },
          {
            // Column
            elem_id: 'cancel_email',
            type: 'InputText',
            typeInputText: 'email',
            label: 'E-mail',
          },
          {
            elem_id: 'cancel_endereco',
            type: 'InputText',
            typeInputText: 'text',
            label: 'Endereço',
          },
        ],
        [
          {
            elem_id: 'cancel_motivo',
            type: 'Select',
            label: 'Motivo do cancelamento',
            value: motivos,
            flex: 5,
          },
        ],
        [
          {
            elem_id: 'web_tipoEndereco',
            type: 'RadioButton',
            name: 'Endereço',
            value: tipoCancelamento,
            disabled: false,
          },
        ],
      ],
    },
  ],
};

export const dadosConfirmacao = {
  table: {
    settings: {
      title: 'Meu plano',
      type: 'headerAside',
      small: true,
    },
    data: [
      [
        {
          label: 'Solicitante',
          value: 'Usuario',
        },
      ],
      [
        {
          label: 'Numero do contrato',
          value: '12345678910',
        },
      ],
      [
        {
          label: 'Produto ANS',
          value: '1234567891‬',
        },
      ],
      [
        {
          label: 'Nome do plano',
          value:
            'PLANO ECONOMICO UNIMED - INDIVIDUAL/FAMILIAR - ENFERMARIA - PLANO ECONOMICO INDIVIDUAL FAMILIAR',
        },
      ],
    ],
  },
};
