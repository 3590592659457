/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import { CheckIcon, WarningIcon, ErrorIcon } from '@solussaude/icons';

import CircularIndeterminate from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import Icon from '@solussaude/core/Helpers/Icon';
import Form from '@solussaude/core/CustomForm';
import RenderStyleJSON from '@solussaude/core/RenderStyleJSON';
import { genKey } from '@solussaude/services/Utils';

import { ContainerDetail } from './styles';

const RenderStatus = ({ index, refGetMapping, refSetMapping }) => {
  const [state, setState] = React.useState({
    loading: false,
    situacao: '',
    response: '{}',
    jsonSend: '',
    detailRenderID: undefined,
  });

  refSetMapping({
    ...refGetMapping(),
    [index]: {
      state,
      setState,
    },
  });

  React.useEffect(() => {
    if (state.detailRenderID !== undefined) {
      state.detailRenderID.setValues({
        ...state.detailRenderID.values,
        jsonReturn: state.response,
        jsonSend:
          state.jsonSend !== ''
            ? state.jsonSend
            : state.detailRenderID.values.jsonSend,
      });
    }
  }, [state.detailRenderID, state.response]);

  if (state.loading) return <CircularIndeterminate margin="none" size={22} />;

  const retrunSituacao = () => {
    if (state.situacao === 'A') {
      return (
        <>
          <Icon icon={CheckIcon} styles={{ color: '#23af11' }}>
            <span style={{ color: '#23af11' }}>Concluído</span>
          </Icon>
        </>
      );
    }
    if (state.situacao === 'E') {
      return (
        <>
          <Icon icon={ErrorIcon} style={{ color: '#dc0f0f' }}>
            <span style={{ color: '#dc0f0f' }}>Erros encontrados</span>
          </Icon>
        </>
      );
    }
    return (
      <>
        <Icon icon={WarningIcon} styles={{ color: '#afa311' }}>
          <span style={{ color: '#afa311' }}>Não verificado</span>
        </Icon>
      </>
    );
  };

  return (
    <>
      <b>{retrunSituacao()}</b>
    </>
  );
};

function RenderDetail({ row, refGetMapping, refSetMapping }) {
  let valuesDetail;
  const string = 'renderDetail';
  const thisRenderIndex = string + row.index;
  const mapping = refGetMapping();
  const [values, setValues] = React.useState({
    jsonSend: row.jsonSend,
    jsonReturn: refGetMapping()[row.index].state.response,
  });

  const formSetValues = (newValues) => {
    valuesDetail = newValues;
  };

  refSetMapping({
    ...refGetMapping(),
    [thisRenderIndex + row.index]: {
      values,
      setValues,
    },
  });

  const atualizaMapeamento = (newValue) => {
    setValues({
      ...values,
      jsonSend: newValue,
    });

    mapping[row.index].setState({
      ...mapping[row.index].state,
      jsonSend: newValue,
    });
  };

  const handleClickReset = () => atualizaMapeamento(row.jsonSend);

  const handleClickChangeJSON = () =>
    atualizaMapeamento(valuesDetail.json_field);

  const singleTest = () => {
    const selectOne = [];
    selectOne[0] = row;
    refGetMapping().webUsuario.onSelectionChange(selectOne);
    refGetMapping().webUsuario.setAllRowsLoading();
  };

  const funMappingComp = () => {
    if (refGetMapping()[row.index].state.detailRenderID === undefined) {
      refGetMapping()[row.index].setState({
        ...refGetMapping()[row.index].state,
        detailRenderID: {
          values,
          setValues,
        },
      });
    }
  };

  React.useEffect(() => {
    funMappingComp();
  });

  const settings = {
    form: [
      {
        label: 'JSON de envio',
        inputs: [
          [
            // Row
            [
              // Column
              <RenderStyleJSON key={genKey()} json={values.jsonSend} />,
            ],
          ],
          [
            {
              // Column
              elem_id: 'json_field',
              type: 'InputText',
              label: 'Enviar outro JSON',
              flex: 6,
            },
          ],
          [
            {
              // Column
              type: 'Button',
              text: 'Testar',
              fullWidth: true,
              href: '',
              flex: 3,
              color: 'secondary',
              variant: 'contained',
              onClick: () => singleTest(),
            },
            {
              // Column
              type: 'Button',
              text: 'Alterar JSON',
              fullWidth: true,
              href: '',
              flex: 3,
              color: 'secondary',
              variant: 'contained',
              onClick: () => handleClickChangeJSON(),
            },
            {
              // Column
              type: 'Button',
              text: 'Voltar JSON Padrão',
              fullWidth: true,
              href: '',
              flex: 3,
              color: 'secondary',
              variant: 'contained',
              onClick: () => handleClickReset(),
            },
          ],
        ],
      },
      {
        label: 'Retorno da requisição',
        inputs: [
          [
            // Row
            [
              // renderJSONResponse
              <RenderStyleJSON key={genKey()} json={values.jsonReturn} />,
            ],
          ],
        ],
      },
    ],
  };

  return (
    <>
      <ContainerDetail>
        <Form settings={settings} setValues={formSetValues} />
      </ContainerDetail>
    </>
  );
}

export const temp = {};
export const renderTable = ({
  refGetMapping,
  refSetMapping,
  onSelectionChange,
}) => ({
  table: {
    options: {
      description:
        'Aqui estão mapeados todas as rotas do modulo Web Prestador, você pode testar todas as requisições de uma vez ou testar apenas uma rota, você pode testar com o JSON padrão ou informar um outro JSON na requisição.',
      editableTable: false,
      pagination: false,
      search: false,
      selection: true,
      onSelectionChange,
      detailPanel: (row) => (
        <RenderDetail
          key={genKey()}
          row={row}
          refGetMapping={refGetMapping}
          refSetMapping={refSetMapping}
        />
      ),
    },
    columns: [
      {
        title: 'Descrição',
        field: 'descricao',
      },
      {
        title: 'Rota',
        field: 'rota',
      },
      {
        title: 'Tipo',
        field: 'tipo',
      },
      {
        title: 'Situação',
        field: 'situacao',
      },
    ],
    data: [
      {
        index: 0,
        descricao: 'Login, tela Login',
        rota: 'api/login',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={0}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"user": "jao", "pwd": "zotape"}',
      },
      {
        index: 1,
        descricao: "Autorização, tela 'Login'",
        rota: 'api/autorizacao',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={1}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"usr": "jao", "pwd": "zotape"}',
      },
      {
        index: 2,
        descricao:
          'Módulos de um prestador, botão no menu superior de listar os módulos',
        rota: 'api/authenticate/modulos',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={2}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"id": 1}',
      },
      {
        index: 3,
        descricao:
          "Alterar senha, tela 'Alterar senha' ou Modal Alterar senha no Login",
        rota: 'api/altera-senha',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={3}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend:
          '{"id": 1, "senhaAtual": "zotape", "novaSenha": "@C123456", "senhaExpira": "N", "dataVencimento": ""}',
      },
      {
        index: 4,
        descricao: "Cadastrar senha, tela 'Cadastrar senha'",
        rota: 'api/cadastrar-senha',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={4}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend:
          '{"cpf": "06195345164", "dataNascimento": "26/03/1998", "senha": "@C123456"}',
      },
      {
        index: 5,
        descricao: "Recuperar senha, tela 'Recuperar senha'",
        rota: 'api/recupera-senha',
        tipo: 'POST',
        situacao: (
          <RenderStatus
            index={5}
            refGetMapping={refGetMapping}
            refSetMapping={refSetMapping}
          />
        ),
        jsonSend: '{"login": "06195345164"}',
      },
    ],
  },
});
