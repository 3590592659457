import styled from 'styled-components';

import defaultTheme from '../../../styles/themes/default';

const theme = defaultTheme;
export const Container = styled.div.attrs({
  className: 'contentCenter',
})`
  ${theme.contentCenter}
  flex: 1;
`;

export const LoginPanel = styled.div.attrs({
  className: 'shadow',
})`
  background: #fff;

  width: 500px;
  min-height: 430px;

  margin-top: 50px;
  margin-bottom: 50px;
  padding-bottom: 35px;
  padding-left: 25px;
  padding-right: 35px;

  border-radius: 5px;
  display: block;
  justify-content: center;
`;

export const LoginHeader = styled.div.attrs({
  className: 'shadow contentCenter',
})`
  flex: 1;
  height: 125px;
  margin-top: -25px;

  /* background: linear-gradient(to right,#42275a ,#b4044a); */
  background: linear-gradient(to right, #f1f1f1, #fff);
  border-radius: 3px;

  h1 {
    color: #fff;
    font-weight: 300;
  }
`;

export const LoginBody = styled.div`
  flex: 1;
  margin-top: 20px;

  h3 {
    font-size: 18px;
    font-weight: bolder;
    color: #000;
    text-align: flex-start;
  }
`;

export const ContainerOption = styled.div`
  flex: 1;
  height: 50px;
  float: right;
`;

export const ContainerWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ContainerPlan = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f7f7f7;
  margin: 15px;
  padding-bottom: 25px;
`;

export const Plan = styled.div.attrs({
  className: 'plan',
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  p {
    font-size: 16px;
    color: #000;
  }
`;

export const TitlePlan = styled.div`
  font-size: 18px;
  color: #000;
`;
