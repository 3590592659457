import Login from '../pages/Login';
import Dashboard from '../pages/Dashboard';
import ForgotPassword from '../pages/ForgotPassword';
import ChangePassword from '../pages/ChangePassword';
import RegisterPassword from '../pages/RegisterPassword';
import NewPassword from '../pages/RegisterPassword/NewPassword';
import LinkAccount from '../pages/RegisterPassword/LinkAccount';
import WithoutAction from '../pages/RegisterPassword/WithoutAction';
import Settings from '../pages/Settings';
import ThemeColor from '../pages/Settings/ThemeColor';
import BeneficiaryArea from '../pages/Settings/BeneficiaryArea';
import EmailSettings from '../pages/Settings/Email';
import Debug from '../pages/Debug';
import TestApp from '../pages/TestApp';
import WebUsuario from '../pages/TestApp/WebUsuario';
import WebPrestador from '../pages/TestApp/WebPrestador';
import Servers from '../pages/Servers';
import Payments from '../pages/Payments';

export const pages = {
  Login,
  Dashboard,
  RegisterPassword,
  NewPassword,
  LinkAccount,
  WithoutAction,
  ForgotPassword,
  ChangePassword,
  Settings,
  ThemeColor,
  BeneficiaryArea,
  EmailSettings,
  Debug,
  TestApp,
  WebUsuario,
  WebPrestador,
  Servers,
  Payments,
};
