/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import axios from 'axios';
import Select from '@solussaude/core/Inputs/Select';
import Icon from '@solussaude/core/Helpers/Icon';
import CircularIndeterminate from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
// import SolusAPI from '@solussaude/services/SolusAPI';
import { CheckCircleIcon, ErrorIcon } from '@solussaude/icons';

let unmountCancel = false;

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

let mapConfigDB;
const getMapConfigDB = () => mapConfigDB;
// eslint-disable-next-line no-return-assign
const mapSetMapConfigDB = (v) => (mapConfigDB = v);

const tempo = [
  { key: 5000, name: '5 segundos' },
  { key: 15000, name: '15 segundos' },
  { key: 30000, name: '30 segundos' },
  { key: 60000, name: '1 minuto' },
];

const verify = (ref, nome) => {
  if (ref()) {
    if (ref()[nome]) {
      return ref()[nome];
    }
  }

  return 6001;
};

const api = (refGetMapping, refSetMapping, index) => {
  const dados = [
    {
      id: 0,
      nome: 'API Autenticação',
      path: 'api_authenticate',
      endereco: `${process.env.REACT_APP_HOST_SERVER}:9001/`,
      // endereco: 'http://ericktanida-gg:9001/',
      status: (
        <RenderStatus
          index={0}
          refGetMapping={refGetMapping}
          refSetMapping={refSetMapping}
        />
      ),
      tempo: verify(refGetMapping, 'API Autenticação'),
      databaseConfig: <RenderConfigDB index={0} />,
    },
    {
      id: 1,
      nome: 'API Beneficiario',
      path: 'api_beneficiario',
      endereco: `${process.env.REACT_APP_HOST_SERVER}:9002/`,
      // endereco: 'http://ericktanida-gg:9002/',
      status: (
        <RenderStatus
          index={1}
          refGetMapping={refGetMapping}
          refSetMapping={refSetMapping}
        />
      ),
      tempo: verify(refGetMapping, 'API Beneficiario'),
      databaseConfig: <RenderConfigDB index={1} />,
    },
    {
      id: 2,
      nome: 'API Agendamentos',
      path: 'api_agendamento',
      endereco: `${process.env.REACT_APP_HOST_SERVER}:9003/`,
      // endereco: 'http://ericktanida-gg:9003/',
      status: (
        <RenderStatus
          index={2}
          refGetMapping={refGetMapping}
          refSetMapping={refSetMapping}
        />
      ),
      tempo: verify(refGetMapping, 'API Agendamentos'),
      databaseConfig: <RenderConfigDB index={2} />,
    },
    {
      id: 3,
      nome: 'API Log',
      path: 'api_logs',
      endereco: `${process.env.REACT_APP_HOST_SERVER}:9004/`,
      // endereco: 'http://ericktanida-gg:9004/',
      status: (
        <RenderStatus
          index={3}
          refGetMapping={refGetMapping}
          refSetMapping={refSetMapping}
        />
      ),
      tempo: verify(refGetMapping, 'API Log'),
      databaseConfig: <RenderConfigDB index={3} />,
    },
    {
      id: 4,
      nome: 'API Atendimento',
      path: 'api_atendimento',
      endereco: `${process.env.REACT_APP_HOST_SERVER}:9007/`,
      // endereco: 'http://ericktanida-gg:9004/',
      status: (
        <RenderStatus
          index={4}
          refGetMapping={refGetMapping}
          refSetMapping={refSetMapping}
        />
      ),
      tempo: verify(refGetMapping, 'API Atendimento'),
      databaseConfig: <RenderConfigDB index={4} />,
    },
    {
      id: 5,
      nome: 'API Prestador',
      path: 'api_prestador',
      endereco: `${process.env.REACT_APP_HOST_SERVER}:9008/`,
      status: (
        <RenderStatus
          index={5}
          refGetMapping={refGetMapping}
          refSetMapping={refSetMapping}
        />
      ),
      tempo: verify(refGetMapping, 'API Prestador'),
      databaseConfig: <RenderConfigDB index={5} />,
    },
    {
      id: 6,
      nome: 'API Comercial',
      path: 'api_comercial',
      endereco: `${process.env.REACT_APP_HOST_SERVER}:9009/`,
      status: (
        <RenderStatus
          index={6}
          refGetMapping={refGetMapping}
          refSetMapping={refSetMapping}
        />
      ),
      tempo: verify(refGetMapping, 'API Comercial'),
      databaseConfig: <RenderConfigDB index={6} />,
    },
    {
      id: 7,
      nome: 'API Settings',
      path: 'api_settings',
      endereco: `${process.env.REACT_APP_HOST_SERVER}:9010/`,
      status: (
        <RenderStatus
          index={7}
          refGetMapping={refGetMapping}
          refSetMapping={refSetMapping}
        />
      ),
      tempo: verify(refGetMapping, 'API Settings'),
      databaseConfig: <RenderConfigDB index={7} />,
    },
    {
      id: 8,
      nome: 'API Request',
      path: 'api_request',
      endereco: `${process.env.REACT_APP_HOST_SERVER}:9011/`,
      status: (
        <RenderStatus
          index={8}
          refGetMapping={refGetMapping}
          refSetMapping={refSetMapping}
        />
      ),
      tempo: verify(refGetMapping, 'API Request'),
      databaseConfig: <RenderConfigDB index={8} />,
    },
    {
      id: 9,
      nome: 'API Documentos',
      path: 'api_documentos',
      endereco: `${process.env.REACT_APP_HOST_SERVER}:9012/`,
      status: (
        <RenderStatus
          index={9}
          refGetMapping={refGetMapping}
          refSetMapping={refSetMapping}
        />
      ),
      tempo: verify(refGetMapping, 'API Documentos'),
      databaseConfig: <RenderConfigDB index={9} />,
    },
    {
      id: 10,
      nome: 'API Payments',
      path: 'api_payments',
      endereco: `${process.env.REACT_APP_HOST_SERVER}:9013/`,
      status: (
        <RenderStatus
          index={10}
          refGetMapping={refGetMapping}
          refSetMapping={refSetMapping}
        />
      ),
      tempo: verify(refGetMapping, 'API Payments'),
      databaseConfig: <RenderConfigDB index={10} />,
    },
  ];

  if (index || index === 0) return [dados[index]];

  return dados;
};

const onChangeTempo = (values, rowData, refGetMapping, refSetMapping) => {
  const { value } = values;

  refGetMapping()[rowData.index].setState((oldState) => ({
    ...oldState,
    timer: value,
  }));

  refSetMapping({
    ...refGetMapping(),
    [rowData.nome]: value,
  });

  api(refGetMapping, refSetMapping, rowData.index);
};

const RenderStatus = ({ index, refGetMapping, refSetMapping }) => {
  const [state, setState] = React.useState({
    loading: true,
    status: 'A',
    data: [],
    refresh: true,
    timer: 6000,
  });

  React.useEffect(() => {
    const reload = () => {
      if (!unmountCancel) {
        api(refGetMapping, refSetMapping, index).forEach(async (item) => {
          setState({ ...state, loading: true });

          if (getMapConfigDB()[index]) {
            getMapConfigDB()[index].setConfigDB('carregando...');
          }
          axios({
            method: 'get',
            url: item.endereco,
            timeout: 2000, // Let's say you want to wait at least 4 mins
          })
            .then((data) => {
              const temp = state.data;
              temp[item.id] = data;
              setState({
                ...state,
                data: temp,
                loading: false,
              });

              let setText = 'Não informado';
              try {
                setText = JSON.parse(data.request.response);
              } catch (e) {
                setText = 'Não informado';
              }

              if (getMapConfigDB()[index]) {
                getMapConfigDB()[index].setConfigDB(setText.databaseConfig);
              }
              setTimeout(() => {
                reload();
              }, item.tempo);
            })
            .catch(() => {
              const temp = state.data;
              temp[item.id] = {};
              setState({
                ...state,
                loading: false,
                data: temp,
              });
              if (getMapConfigDB()[index]) {
                getMapConfigDB()[index].setConfigDB('...');
              }
              setTimeout(() => {
                reload();
              }, item.tempo);
            });
        });
      }
    };
    reload();

    return () => {
      unmountCancel = true;
    };
  }, []);

  refSetMapping({
    ...refGetMapping(),
    [index]: {
      state,
      setState,
    },
  });

  const returnSituacao = () => {
    if (state.data[index]) {
      if (state.data[index].status === 200) {
        return (
          <>
            <Icon icon={CheckCircleIcon} styles={{ color: '#23af11' }}>
              <span style={{ color: '#23af11' }}>Ligado</span>
            </Icon>
          </>
        );
      }
    }
    return (
      <>
        <Icon icon={ErrorIcon} styles={{ color: '#dc0f0f' }}>
          <span style={{ color: '#dc0f0f' }}>Desligado</span>
        </Icon>
      </>
    );
  };

  return (
    <>
      {state.loading === false ? (
        <b>{returnSituacao()}</b>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <CircularIndeterminate
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              margin="none"
              size={22}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <span style={{ color: 'rgb(175, 163, 17)', marginLeft: 15 }}>
              <b>Verificando</b>
            </span>
          </div>
        </div>
      )}
    </>
  );
};

const RenderConfigDB = ({ index }) => {
  const [configDB, setConfigDB] = React.useState('sad');
  if (index || index === 0) {
    mapSetMapConfigDB({
      ...getMapConfigDB(),
      [index]: {
        configDB,
        setConfigDB,
      },
    });
  }

  return <p>{configDB}</p>;
};

const processData = (apiData) => {
  const array = [];
  apiData.forEach((server, i) => {
    array.push({
      index: i,
      nome: server.nome,
      endereco: server.endereco,
      status: server.status,
      tempo: server.tempo,
      path: server.path,
      databaseConfig: server.databaseConfig,
    });
  });

  return array;
};

export const temp = {};
export const renderTable = ({ refGetMapping, refSetMapping }) =>
  // apiData,
  // handleLigar,
  // handleDesligar
  ({
    table: {
      options: {
        description: 'Essa tabela lista os servidores disponíveis',
      },
      columns: [
        {
          title: 'Nome',
          field: 'nome',
        },
        {
          title: 'Endereço',
          field: 'endereco',
        },
        {
          title: 'Status do servidor',
          field: 'status',
        },
        {
          title: 'Path',
          field: 'path',
          hidden: true,
        },
        {
          title: 'Tempo para requisição',
          field: 'tempo',
          render: (rowData) => (
            <Select
              style={{ marginTop: 0 }}
              data={{
                elem_id: 'tempo',
                type: 'Select',
                label: 'Tempo',
                value: tempo,
                onChange: (values) =>
                  onChangeTempo(values, rowData, refGetMapping, refSetMapping),
              }}
              refGetValues={getFormValues}
              refSetValues={setFormValues}
            />
          ),
        },
        {
          title: 'Configuração banco',
          field: 'databaseConfig',
        },
        // {
        //   title: 'Ações',
        //   field: 'acoes',
        //   render: rowData => (
        //     <>
        //       <ActionButtons direction="flex-start">
        //         <Button
        //           style={{ marginRight: 15 }}
        //           text="Ligar"
        //           variant="contained"
        //           color="primary"
        //           endIcon={<PowerSettingsNewIcon />}
        //           fullWidth={false}
        //           onClick={() => handleLigar(rowData)}
        //         />
        //         <Button
        //           text="Desligar"
        //           variant="contained"
        //           color="secondary"
        //           endIcon={<PowerSettingsNewIcon />}
        //           fullWidth={false}
        //           onClick={() => handleDesligar(rowData)}
        //         />
        //       </ActionButtons>
        //     </>
        //   ),
        // },
      ],
      data: processData(api(refGetMapping, refSetMapping)),
      // data: processData(apiData),
    },
  });
