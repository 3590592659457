/**
 *  Solus form generate
 *
 *  {{ REPRESENTAÇÃO }}
 *  Form
 *    |---Label (Nome desse form de inputs)
 *    |---inputs
 *          |---- Component JSX
 *            ou
 *          |---- row  (Representação de uma ROW todos inputs gerado, estarão nessa Linha)
 *                 |--- inputs  (Você pode ter mais de 1 input na mesma linha, você também
 * pode setar a sua largura usando FLEX com o total 12)
 *
 *  Footer
 *    |---Input ( Mesmo funcionamento )
 *
 *  {{ DESCRIÇÃO }}
 *
 *  Tag form recebe um array, você pode ter mais de um conjuto de input, caso tenha mais
 * de 1, será gerado tabulação
 *
 *
 */

export const renderForm = ({ dataAPI }) => ({
  header: {
    description:
      'As configurações realizadas serão armazenadas em banco de dados, caso não exista configurações, as cores default serão utilizadas.',
  },
  form: [
    {
      label: 'Color WEB',
      inputs: [
        [
          // Row
          {
            // Column
            elem_id: 'web_color_primary',
            elem_val: dataAPI.palette.primary.main,
            type: 'InputText',
            label: 'Color primary',
            flex: 9,
            description:
              'A cor PRIMARY é a cor principal do site, sendo o background, Appbar, Menubar etc...',
          },
        ],
        [
          // Row
          {
            // Column
            elem_id: 'web_color_secondary',
            elem_val: dataAPI.palette.secondary.main,
            type: 'InputText',
            label: 'Color secondary',
            flex: 9,
            description:
              'A cor SECONDARY é a cor utilizada para acompanhar a cor PRIMARY',
          },
        ],
        [
          {
            // Column
            elem_id: 'web_background_color',
            elem_val: dataAPI.palette.background.body,
            type: 'InputText',
            label: 'Background color',
            flex: 9,
            description:
              'Essa configuração é aplicada no background de todas as página, será a cor de fundo.',
          },
        ],
        [
          {
            // Column
            elem_id: 'web_background_gradient',
            elem_val: dataAPI.palette.background.linearGradient,
            type: 'InputText',
            label: 'Background gradient color',
            flex: 9,
            description:
              'Essa configuração é aplicada nos componentes onde é utilizado o gradient.',
          },
        ],
        [
          {
            // Column
            elem_id: 'web_background_header_title',
            elem_val: dataAPI.palette.background.headerTitle,
            type: 'InputText',
            label: 'Background header title color',
            flex: 9,
            description:
              'Essa configuração é aplicada nos componentes de titulos de uma page.',
          },
        ],
        [
          {
            // Column
            elem_id: 'web_background_button_access',
            elem_val: dataAPI.palette.background.buttonAccess,
            type: 'InputText',
            label: 'Background button access',
            flex: 9,
            description:
              'Essa configuração é aplicada nos componentes de botões para acessos.',
          },
        ],
        [
          {
            // Column
            elem_id: 'web_background_icons',
            elem_val: dataAPI.palette.background.colorIcons,
            type: 'InputText',
            label: 'Background icon color',
            flex: 9,
            description:
              'Essa configuração é aplicada nos componentes de icons.',
          },
        ],
        [
          {
            // Column
            elem_id: 'web_background_cards',
            elem_val: dataAPI.palette.background.colorCards,
            type: 'InputText',
            label: 'Background card color',
            flex: 9,
            description:
              'Essa configuração é aplicada nos componentes de cards (Utilizado em card de mensalidade Menu principal).',
          },
        ],
      ],
    },
    {
      label: 'Font color',
      inputs: [
        [
          // Row
          {
            // Column
            elem_id: 'web_fontC_primary',
            elem_val: dataAPI.palette.primary.contrastText,
            type: 'InputText',
            label: 'Font color primary',
            description:
              'Esse campo está destinado a todas as fontes que estiverem dentro de um background de cor PRIMARY definido em "Color WEB".',
            flex: 9,
          },
        ],
        [
          {
            // Column
            elem_id: 'web_fontC_secondary',
            elem_val: dataAPI.palette.secondary.contrastText,
            type: 'InputText',
            label: 'Font color secondary',
            description:
              'Esse campo está destinado a todas as fontes que estiverem dentro de um background de cor SECONDARY definido em "Color WEB".',
            flex: 9,
          },
        ],
        [
          {
            elem_id: 'web_font_color',
            type: 'InputText',
            label: 'Font color text',
            description:
              'Esse campo está destinado a todos os textos, descrições e conteúdos da web em um background branco.',
            flex: 9,
          },
        ],
        [
          {
            // Column
            elem_id: 'web_fontC_gradient',
            elem_val: dataAPI.palette.background.linearColorGradient,
            type: 'InputText',
            label: 'Font background gradient color',
            flex: 9,
            description:
              'Essa configuração é aplicada nos textos que estão dentro de um componente onde é utilizado o gradient.',
          },
        ],
      ],
    },
  ],
});

export const renderFormFont = {
  header: {
    description:
      'As configurações realizadas serão armazenadas em banco de dados, caso não exista configurações, os tamanhos default serão utilizados.',
  },
  form: [
    {
      label: 'Font custom',
      inputs: [
        [
          // Row
          {
            // Column
            elem_id: 'web_font_h1',
            type: 'InputText',
            label: 'Tamanho título h1',
            description:
              'Os títulos H1 são utilizados uma única vez, ele é o título principal de uma página.',
          },
        ],
        [
          // Row
          {
            // Column
            elem_id: 'web_font_h2',
            type: 'InputText',
            label: 'Tamanho título h2',
            description: 'Os títulos H2 são utilizado em uma PAGE.',
          },
        ],
        [
          // Row
          {
            // Column
            elem_id: 'web_font_h3',
            type: 'InputText',
            label: 'Tamanho título h3',
            description:
              'Os títulos H3 são utilizado dentro de uma PAGE, sendo o título do conteúdo.',
          },
        ],
        [
          // Row
          {
            // Column
            elem_id: 'web_font_body',
            type: 'InputText',
            label: 'Tamanho texto body',
            description:
              'Essa configuração será aplicada nos textos de uma página.',
          },
        ],
      ],
    },
  ],
};
