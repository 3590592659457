import React from 'react';

import { SendIcon } from '@solussaude/icons';

export const temp = {};
export const renderForm = ({ onHandleClick }) => {
  const label = 'Cadastrar senha';
  return {
    header: {
      description:
        'Informe o CPF e a data de nascimento para prosseguir com o cadastro de senha.',
    },
    form: [
      {
        label,
        inputs: [
          [
            {
              elem_id: 'cpf',
              type: 'InputMask',
              label: 'CPF',
              required: true,
              typeMask: 'cpf',
            },
          ],
          [
            {
              elem_id: 'dataNascimento',
              type: 'InputText',
              label: 'Data de Nascimento',
              required: true,
              shrink: true,
              typeInputText: 'date',
            },
          ],
          [
            {
              elem_id: 'btnSubmit',
              type: 'Button',
              text: 'Próximo',
              fullWidth: false,
              onClick: () => onHandleClick(),
              color: 'primary',
              variant: 'contained',
              endIcon: <SendIcon />,
            },
          ],
        ],
      },
    ],
  };
};
