/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';

import Form from '@solussaude/core/CustomForm';
import WebContext from '@solussaude/services/WebContext';
import { CircularIndeterminateModal } from '@solussaude/core/Feedback/Progress/CircularIndeterminate';
import BodyInfo from '@solussaude/core/Helpers/BodyInfo';
import Button from '@solussaude/core/Inputs/Button';
import ActionButtons from '@solussaude/core/Helpers/ActionButtons';
import { WarningIcon, CheckCircleIcon } from '@solussaude/icons';

import SolusAPI from '@solussaude/services/SolusAPI';
import { renderForm } from './settings';
import { Container, LoginPanel, LoginHeader, LoginBody } from './styles';
import defaultTheme from '../../../styles/themes/default';

let formValues;
const getFormValues = () => formValues;
const setFormValues = (newValue) => {
  formValues = newValue;
};

export default function NewPassword(props) {
  const { location } = props;
  const webContext = React.useContext(WebContext);
  const theme = defaultTheme;
  const [state, setState] = React.useState({ loading: false });

  const handleCloseModal = () => {
    webContext.showModal({
      open: false,
    });
  };

  const onHandleClick = async () => {
    const jsonSend = getFormValues();

    setState({ loading: true });

    setTimeout(() => {
      setState({ loading: false });
    }, 1500);

    if (jsonSend.senha !== jsonSend.repetir_senha) {
      webContext.showModal({
        open: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<WarningIcon color="error" />}
              title="Senhas diferentes!"
              body="As senhas informadas não são iguais!"
            />
            <ActionButtons direction="flex-end">
              <Button
                text="Fechar"
                variant="contained"
                color="secondary"
                fullWidth={false}
                onClick={() => handleCloseModal()}
              />
            </ActionButtons>
          </>
        ),
      });
    }
    const dados = await SolusAPI.post(
      'api/v1/operadores/store',
      jsonSend,
      false,
    );

    if (dados.success) {
      webContext.showModal({
        open: true,
        renderBody: (
          <>
            <BodyInfo
              icon={<CheckCircleIcon color="success" />}
              title="Senha cadastrada com sucesso!"
              body="!"
            />
          </>
        ),
      });
    }

    webContext.showModal({
      open: true,
      renderBody: (
        <>
          <BodyInfo
            icon={<WarningIcon color="error" />}
            title="Cadastro de erro!"
            body="Ops... tente novamente!"
          />
          <ActionButtons direction="flex-end">
            <Button
              text="Fechar"
              variant="contained"
              color="secondary"
              fullWidth={false}
              onClick={() => handleCloseModal()}
            />
          </ActionButtons>
        </>
      ),
    });
  };

  const onHandleClickBack = () => {
    props.history.push('/adm/login');
  };

  const settings = renderForm(
    { onHandleClick, onHandleClickBack },
    location.state,
  );
  return (
    <Container>
      <LoginPanel>
        <LoginHeader>
          <img className="auth_logo" src={theme.logo} alt="User" />
        </LoginHeader>
        {state.loading ? (
          <CircularIndeterminateModal />
        ) : (
          <LoginBody>
            <h3>Cadastro de senha</h3>
            <Form settings={settings} setValues={setFormValues} />
          </LoginBody>
        )}
      </LoginPanel>
    </Container>
  );
}
